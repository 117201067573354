import localforage from 'localforage'
import isFunc from 'lodash/isFunction'

export const state = () => ({
  loading: false,
  id: null,
  url: null,
  metafields: []
})

export const mutations = {
  setLoading(state, value) {
    state.loading = !!value
  },
  setId(state, payload) {
    localforage.setItem('checkout-id', payload)
    state.id = payload
  },
  setUrl(state, payload) {
    localforage.setItem('checkout-url', payload)
    state.url = payload
  },
  setCheckout(state, { id, url }) {
    localforage.setItem('checkout-id', id)
    localforage.setItem('checkout-url', url)
    state.id = id
    state.url = url
  },
  setMetafields(state, payload) {
    state.metafields = payload
  }
}

export const actions = {
  async initializeCheckout({ commit, dispatch }) {
    const id = await localforage.getItem('checkout-id')
    const url = await localforage.getItem('checkout-url')
    if (id && url) {
      let completed = false
      try {
        const checkoutResult = await this.$shopifyCheckout.get({ id })
        if (checkoutResult) {
          completed = checkoutResult.completed
        }
      } catch (err) {
        console.warn(err)
        completed = true
      }
      if (completed) {
        await dispatch('resetCheckout')
      } else {
        commit('setCheckout', { id, url })
      }
    }
  },

  async resetCheckout({ commit, dispatch }) {
    commit('setCheckout', { id: null, url: null })
    await dispatch('cart/resetLineItems', null, { root: true })
  },

  async processCheckout({ state, commit, dispatch }, { beforeCreate, beforeRedirect }) {
    if (state.loading) {
      console.warn('[store/checkout] loading..')
    } else {
      try {
        commit('setLoading', true)
        if (isFunc(beforeCreate)) {
          await beforeCreate()
        }
        await dispatch('checkoutCreate')
        await dispatch('addCheckoutParams')
        if (isFunc(beforeRedirect)) {
          await beforeRedirect()
        }
        dispatch('checkoutRedirect')
        commit('setLoading', false)
      } catch (err) {
        commit('setLoading', false)
        throw err
      }
    }
  },

  async checkoutCreate(ctx) {
    let checkout
    const { commit, dispatch, state, rootState } = ctx
    if (this.$config.drtv.enabled) {
      await dispatch('addMetafields', { key: 'channel', value: 'DRTV' })
    }
    const cartItems = await dispatch(
      'cart/checkoutLineItems',
      null,
      { root: true }
    )
    const checkoutId = state.id || ''
    const metafields = state.metafields
    if (cartItems.length === 0) {
      throw new Error('Cannot checkout with an empty cart')
    }
    if (Array.isArray(metafields) && metafields.length) {
      checkout = await this.$shopifyCheckout.process({
        cartItems,
        checkoutId,
        metafields
      })
    } else {
      checkout = await this.$shopifyCheckout.process({
        cartItems,
        checkoutId
      })
    }
    if (checkout && checkout.completed) {
      checkout = await this.$shopifyCheckout.process({
        cartItems,
        checkoutId: ''
      })
    }
    if (!checkout || !checkout.id || !checkout.url) {
      throw new Error('Checkout Failure')
    }
    if (rootState.events) {
      dispatch(
        'events/checkoutInit',
        { cart: rootState.cart.lineItems },
        { root: true }
      )
    }
    commit('setCheckout', checkout)
  },

  async addCheckoutParams({ commit, state, rootGetters }) {
    const userData = rootGetters['user/userData']
    const queryOperator = state.url.includes('?') ? '&' : '?'
    await commit(
      'setUrl',
      `${state.url}${queryOperator}c=${JSON.stringify(userData)}`
    )
  },

  addMetafields({ commit, state }, ...metafields) {
    commit('setMetafields', [state.metafields, metafields].flat())
  },

  checkoutRedirect({ state }) {
    if (process.browser) {
      window.location = state.url
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
