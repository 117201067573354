import debounce from 'lodash/debounce'
import flow from 'lodash/flow'
import get from 'lodash/get'
import isString from 'lodash/isString'
import type { ISbStoriesParams } from 'storyblok-js-client'
import type StoryblokClient from 'storyblok-js-client'
import type Vue from 'vue'
import type VueRouter from 'vue-router'
import { mapStory, Story } from './helper'

/**
 * reusable storyblok bridge setup function
 *
 * works with any vue component, but try not to have too many instances per
 * page.
 *
 * ```
 * export default {
 *   created() {
 *     this.unsubscribeStoryblok = await setupBridge(this, (story) => {
 *       this.page = story // update handler
 *     })
 *   },
 *   beforeDestory() {
 *     if (this.unsubscribeStoryblok) {
 *       this.unsubscribeStoryblok()
 *     }
 *   }
 * }
 * ```
 */
export async function setupBridge(
  { $router, $storyblok }: BridgeContext,
  config: { namespace: string | void },
  updateHandler: (story: any) => void,
  options?: {
    handles?: Array<string> // limit updates to a specific list of handles
  }
): Promise<Function> {
  if (process.client) {
    console.log('[storyblok-bridge] created')
    const bridge = await $storyblok.ready()
    const handleInput = debounce(flow(mapStory, updateHandler), 400)
    const shouldSkip = (slug: string): boolean => {
      if (
        options &&
        options.handles &&
        options.handles.length
      ) {
        return !options.handles.includes(slug)
      } else {
        return false
      }
    }
    const resolveStory = async (
      sb: StoryblokClient ,
      { slugChanged, storyId }: { slugChanged: boolean, storyId: string | number }
    ) => {
      try {
        let query: ISbStoriesParams = {
          version: 'draft'
        }
        // if the preview query indicates we are in a release, fetch it.
        if ($router.currentRoute.query._storyblok_release) {
          query.from_release = get($router, 'currentRoute.query._storyblok_release')
        }
        const response = await sb.get(`cdn/stories/${storyId}`, query)
        let { story }: { story: Story<any> } = response.data
        if (story) {
          if (shouldSkip(story.slug)) {
            // if handles are listed, but none match the current event, do nothing.
            //console.warn('skipped')
            return
          }
          if (slugChanged) {
            console.log('[storybook-bridge] updated url', { slugChanged, story })
            $router.push(`/${story.path || story.full_slug}`)
          } else {
            handleInput(story)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
    const events = ['published', 'change', 'input']
    const handler = (e: StoryblokBridgeEvent) => {
      console.log('[storyblok-bridge] event', e)
      const sb = $storyblok.client
      if (shouldSkip(e.slug)) {
        // if handles are listed, but none match the current event, do nothing.
        //console.warn('skipped')
        return
      }
      switch (e.action) {
        case 'change':
          resolveStory(sb, e)
          break
        case 'published':
          resolveStory(sb, e)
          break
        case 'input':
          handleInput(e.story)
          break
      }
    }
    // NOTE: the bridge subscription is a SINGLETON, subscribing again replaces
    // the existing subscription.
    bridge.on(events, handler)
    // in the preview environment, load the story as soon as we know the id so
    // we don't have to share draft data with the nacelle index.
    if (
      $router.currentRoute.query._storyblok &&
      isString($router.currentRoute.query._storyblok)
    ) {
      console.log('[storyblok-bridge] loading preview..')
      resolveStory($storyblok.client, {
        slugChanged: false,
        storyId: $router.currentRoute.query._storyblok
      })
    }
    return () => {
      try {
        bridge.off(events, handler)
      } catch (err) {
        //
      }
    }
  } else {
    return () => {}
  }
}
interface StoryblokBridge {
  on(events: Array<string>, handler: Function): void
  off(events: Array<string>, handler: Function): void
}
interface BridgeContext {
  $storyblok: {
    ready(): Promise<StoryblokBridge>
    client: StoryblokClient
  }
  $router: VueRouter
}
interface StoryblokPublishEvent {
  action: 'published' | 'change'
  slugChanged: boolean
  slug: string
  storyId: string | number
}
interface StoryblokInputEvent {
  action: 'input'
  slugChanged: boolean
  slug: string
  story: any
}
type StoryblokBridgeEvent = StoryblokPublishEvent | StoryblokInputEvent

interface ISbResponse {
  data: any
  status: number
  statusText: string
  headers: any
  config: any
  request: any
}
