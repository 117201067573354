import localforage from 'localforage'
/**
 * generate an approximate number for a day
 * @return {number}
 */
const getDate = () => parseInt((new Date().getTime() / 1000) / 86400)
/**
 * @param {string}
 * @return {string}
 */
const toKey = (label) => `user-flag::${label}`
/**
 * activate a flag
 * @param {string} flag
 */
export const setFlag = async (flag) => {
  const key = toKey(flag)
  await localforage.setItem(key, getDate())
}
/**
 * check if a flag is active
 * @param {string} flag
 * @return {boolean}
 */
export const getFlag = async (flag) => {
  const key = toKey(flag)
  const value = await localforage.getItem(key)
  let result = false
  if (value) {
    const date = parseInt(value)
    const now = getDate()
    const timeout = parseInt(process.env.USER_FLAG_TIMEOUT)
    if ((now - date) > timeout) {
      console.log(`[user-flags] ${key} is expired, removing...`, {
        date,
        now,
        timeout
      })
      await localforage.removeItem(flag)
    } else {
      console.log(`[user-flags] ${key} is active`, { date, now, timeout })
      result = true
    }
  }
  return result
}
