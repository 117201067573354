export const state = () => ({
  exchange: false
})

export const mutations = {
  setExchange(state, payload) {
    state.exchange = payload
  }
}

export const actions = {
  async setExchange({ commit }, payload) {
    commit("setExchange", payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
