import flow from 'lodash/fp/flow'
// import clone from 'lodash/cloneDeep'
/**
 * flatten the given mixin options by key
 * @param {string} key
 * @param {array} mixins
 * @param {array} list
 */
const flattenMixinsByKey = (key, mixins, list) => {
  let flatWithKey = list || []
  for (let mixin of mixins) {
    if (mixin.mixins) {
      flattenMixinsByKey(key, mixin.mixins, flatWithKey)
    }
    if (mixin[key] && !flatWithKey.includes(mixin)) {
      flatWithKey.push(mixin)
    }
  }
  return flatWithKey
}
/**
 * higher-order implementation of nmerge, the target key (was asyncData) is
 * parameterized
 *
 * @param {object} options
 * @param {string} options.key
 * @param {object} options.comp
 */
const mergeByKey = ({ key, comp }) => {
  if (comp && comp.mixins) {
    const mixins = flattenMixinsByKey(key, comp.mixins)
    if (mixins.length) {
      let methods = []
      for (let mixin of mixins) {
        // console.warn(key, mixin)
        if (mixin[key]) {
          methods.push(mixin[key])
        }
      }
      if (comp[key]) {
        methods.push(comp[key])
      }
      // console.log('nmerge/fetch', { key, methods, mixins, comp: clone(comp) })
      if (key === 'asyncData') {
        comp[key] = async (context) => {
          const promises = methods.map(method => method(context))
          const values = await Promise.all(promises)
          return Object.assign.apply(Object, values)
        }
      } else if (key === 'fetch') {
        comp[key] = async function () {
          const args = [...arguments]
          // execute comp'd fetches sequentially
          for (let method of methods) {
            await method.apply(this, ...args)
          }
        }
      }
    }
  }
  return comp
}
/**
 * curried mergeByKey to act like nmerge
 * @param {object} comp
 */
const mergeAsync = (comp) => mergeByKey({ key: 'asyncData', comp })
/**
 * curried mergeByKey to act like nmerge, but for the newer fetch method
 * @param {object} comp
 */
const mergeFetch = (comp) => mergeByKey({ key: 'fetch', comp })
/**
 * dispatch fetch and asyncData requests in parallel. if a component has more
 * than one mixin that uses either fetch or asyncData, it needs to be wrapped in
 * this function in order for all of the requests to be dispatched correctly.
 *
 * this function is based on nmerge, but adds support for fetch requests
 *
 * @link https://github.com/n1kk/nuxt-merge-asyncdata/
 *
 * @param {object} comp
 */
export default flow(mergeAsync, mergeFetch)
