import flow from 'lodash/fp/flow'
import range from 'lodash/range'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'
import sum from 'lodash/sum'
import toNumber from 'lodash/toNumber'
const clone = flow(JSON.stringify, JSON.parse)
const params = {
  shopify: {
    sortByIteratees: [ ({ variant }) => toNumber(variant.price) ]
  }
}
/**
 * generalized BxGy constructor
 *
 * @params {object} options
 * @param {string} [options.variant="shopify"] - used to control the application
 * of bxgy to match specific systems.
 */
function BxGy({
  buysHandles,
  buysQuantity,
  buysThreshold,
  getsDiscountDecimal,
  getsQuantity,
  getsHandles,
  lineItems,
  usesLimit,
  variant = 'shopify'
}) {
  this.params = params[variant || 'shopify']
  // values
  this.buysHandles = clone(buysHandles)
  this.buysQuantity = clone(buysQuantity)
  this.buysThreshold = buysThreshold
  this.getsDiscount = getsDiscountDecimal
  this.getsQuantity = getsQuantity || 1
  this.getsHandles = clone(getsHandles)
  // partial application to break up the computation as much as we can without
  // being confusing. this object is constructed every time the cart changes,
  // and we don't want to hog the thread.
  // this.lineItems = lineItems
  this.lineItems = sortBy(clone(lineItems), this.params.sortByIteratees)
  this.usesLimit = this.getUses(usesLimit || -1)
}
/**
 * compute a uses limit based on the given options and the maximum possible BxGy
 * uses given the number of lineItems
 * @param {number} usesLimit
 */
BxGy.prototype.getUses = function (usesLimit) {
  let result = 0
  const getsQuantity = this.getsQuantity
  const buys = this.lineItems
    .filter(({ handle }) => this.buysHandles.includes(handle))
    .map(({ handle, quantity }) => ({ handle, quantity }))
  const totalQuantity = sum(buys.map(({ quantity }) => quantity))
  const getsOverlap = buys
    .filter(({ handle }) => this.getsHandles.includes(handle))
    .map(({ handle, quantity }) => ({ handle, quantity }))
  const overlapFactor = (getsOverlap.length > 0)
    ? (buys.length / getsOverlap.length) * 0.5
    : 1
  const maxUses = Math.floor(totalQuantity * getsQuantity * overlapFactor)
  result = (usesLimit > -1)
    ? Math.min(usesLimit, maxUses)
    : maxUses
  // console.log('BxGy', { overlapFactor, buys: buys.length, gets: gets.length, uses: result })
  return result
}
BxGy.prototype.mapSlotsToResult = function (slots) {
  let ids = {}
  let result = []
  for (let item of this.lineItems) {
    // eslint-disable-next-line no-unused-vars
    for (let i of range(0, item.quantity)) {
      const qualifies = this.getsHandles.includes(item.handle)
      const slotIndex = slots.findIndex((slot) => {
        return (slot && slot.slice(-1).length)
          ? slot.slice(-1)[0] === null
          : false
      })
      if (qualifies && (slotIndex > -1)) {
        slots[slotIndex].splice(-1, 1, item.id)
      } else {
        break
      }
    }
  }
  for (let slot of slots) {
    if (slot && slot.length && slot.slice(-1).length) {
      const id = slot.slice(-1)[0]
      if (ids[id] === undefined) {
        ids[id] = 1
      } else {
        ids[id] += 1
      }
    }
  }
  for (let [id, qty] of Object.entries(ids)) {
    const item = this.lineItems.find((item) => item.id === id)
    if (item && item.handle) {
      result.push({
        hasBogoDiscount: true,
        handle: item.handle,
        id: id,
        prop: {
          key: '_local_bogo',
          value: qty
        }
      })
    }
  }
  return result
}
/**
 * qualify items for a BxGy offer using QUANTITY
 */
BxGy.prototype.getQuantityResult = function () {
  const buysHandles = this.buysHandles
  const buysQuantity = this.buysQuantity
  const getsQuantity = this.getsQuantity
  const usesLimit = this.usesLimit
  let bxgySlots = Array(usesLimit)
  let useIndex = 0
  let slot = []
  for (let lineItem of reverse(clone(this.lineItems))) {
    // eslint-disable-next-line no-unused-vars
    for (let i of range(0, lineItem.quantity * getsQuantity)) {
      if (useIndex === usesLimit) {
        break
      }
      const qualifies = buysHandles.includes(lineItem.handle)
      if (qualifies && (slot.length < buysQuantity)) {
        slot.push(lineItem)
      }
      if ((slot.length === buysQuantity)) {
        bxgySlots[useIndex] = [...slot, null]
        slot = []
        useIndex += 1
      }
    }
    // console.log('bxgy', { useIndex, usesLimit })
    if (useIndex === usesLimit) {
      break
    }
  }
  return bxgySlots
}

BxGy.prototype.getValueResult = function () {
  throw new Error('bxgy.getValueResult is not implemented yet')
}

Object.defineProperty(BxGy.prototype, 'result', {
  get() {
    // lineItems -> { hasBogoDiscount, handle, id, prop }
    // prop: { key: '_local_bogo', value }
    // value -> n of these have bogo (for line items with quantity > 1)
    let result = null
    if (this.buysQuantity) {
      result = this.getQuantityResult()
    } else if (this.buysThreshold) {
      result = this.getValueResult()
    }
    if (result) {
      const mappedResult = this.mapSlotsToResult(result)
      // console.log('BxGy', { getsQuantity: this.getsQuantity, limit: this.usesLimit, mappedResult })
      return mappedResult
    } else {
      return null
    }
  }
})

export default BxGy
