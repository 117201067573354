export const state = () => ({
  menuVisible: false,
  globalHeaderVisible: true,
  globalFooterVisible: true,
})

export const mutations = {
  openMenu(state) {
    state.menuVisible = true
  },

  toggleShowMenu(state) {
    state.menuVisible = !state.menuVisible
  },

  disableMenu(state) {
    state.menuVisible = false
  },

  showHeader(state) {
    state.globalHeaderVisible = true;
  },

  hideHeader(state) {
    state.globalHeaderVisible = false;
  },

  showFooter(state) {
    state.globalFooterVisible = true;
  },

  hideFooter(state) {
    state.globalFooterVisible = false;
  },
}
export default {
  namespaced: true,
  state,
  mutations
}
