import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import clone from 'lodash/clone'
import Memoize, { MemCache } from '@relax/async-utils/memoize'
import { fromBase64 } from '~/utils/base64'
// @ts-ignore
import { parseId } from '@/utils/parseIds'
import { AxiosInstance } from 'axios'
import { getProductVariantStock, getCollectionProductStock } from '~/queries'
import { graphql } from '~/utils/graphql'
// @ts-ignore
import { mapContent } from '@enso-rings/component-library/src/utils/contentfulHelper'
import { mapStoryContent } from '~/modules/storyblok/helper'
import { Store } from 'vuex'
import {
  isValidHandle,
  getCollection, getCollections,
  getContentItem, getBlog, getArticle, getPage,
  getProduct, getProducts
} from './dataLoaders'
import {
  StockData,
  ArticleList,
  Blog,
  BlogData,
  Collection,
  CollectionData,
  Product,
} from '~/queries/schemas'
/**
 * internal data api, use this interface to access *any* data source. we
 * occasionally change upstream providers, this pattern simplifies the process
 * of migration by avoiding global code changes. keep the existing schemas in
 * mind (they are listed at the bottom of this file).
 *
 * ```
 * this.$api.getProduct({ handle })
 * this.$api.getCollection({ handle })
 * ```
 *
 * within headless/nuxt, the api is setup in this way:
 *
 * ```
 * import ApiClient from '~/utils/ApiClient'
 * export default (ctx, inject) => {
 *   // used as $api, the $ is added by nuxt
 *   inject('api', new ApiClient(ctx))
 * }
 * ```
 */
export default class ApiClient {
  $nacelle: any;
  $store: Store<any>;
  $axios: AxiosInstance;
  defaultLocale: string;
  _gqlCache: MemCache;
  _dataLoaderCache: MemCache;

  // helpers
  constructor({ $nacelle, $axios, store, defaultLocale }: ApiClientOptions) {
    this.defaultLocale = defaultLocale || 'en-us'
    this.$nacelle = $nacelle
    this.$store = store
    this.$axios = $axios
    // @link https://gitlab.com/r14c/relax.js/-/blob/main/async-utils/README.md#module_memoize
    // since we can reliably identify products by handle, we can use a cheap
    // identity function here.
    this._gqlCache = Memoize((args: any) => {
      return [
        get(args, '[2][0].query'),
        ...Object.values(get(args, '[2][0].variables'))
      ].join('--')
    })
    // using default identity
    this._dataLoaderCache = Memoize()
  }
  private _withDefaultLocale<T>(options: { [index: string]: any } & T): { locale: string } & T {
    return { ...options, locale: options.locale || this.defaultLocale }
  }

  //
  // product data loaders
  //

  /**
   * memcache wrapper for data loader functions, helps avoid hitting the network
   * for repeated fetches of the same resource.
   */
  private _getCached(fn: Function, options: any) {
    return this._dataLoaderCache(
      (args: any) => {
        return fn({ ...args, $nacelle: this.$nacelle, $axios: this.$axios })
      },
      [options],
      1000
    )
  }
  getProduct({ handle, locale }: GetItemOptions): Promise<Product> {
    return this._getCached(getProduct, this._withDefaultLocale({ handle, locale }))
  }
  getProducts({ handles, locale }: GetItemsOptions): Promise<Array<Product>> {
    return this._getCached(getProducts, this._withDefaultLocale({ handles, locale }))
  }
  getCollection({
    handle,
    locale,
    itemsPerPage,
    index,
    selectedList,
    sortOptions,
    omitProducts,
  }: GetCollectionOptions): Promise<CollectionData> {
    const options = this._withDefaultLocale({
      handle,
      locale,
      itemsPerPage: ((itemsPerPage === null) || (itemsPerPage === false))
        ? undefined
        : itemsPerPage || 30,
      index,
      selectedList,
      sortOptions,
      omitProducts,
    })
    return this._getCached(getCollection, options)
  }
  async getCollectionProducts({
    handle,
    locale,
    itemsPerPage,
    index,
    selectedList,
    sortOptions,
  }: GetCollectionOptions): Promise<Array<Product>> {
    const collection = await this.getCollection({
      handle,
      locale: locale || this.defaultLocale,
      itemsPerPage: ((itemsPerPage === null) || (itemsPerPage === false))
        ? undefined
        : itemsPerPage || 30,
      index,
      selectedList,
      sortOptions,
    })
    return collection && collection.products
  }
  /**
   * @param {object} options
   * @param {string[]} options.collections - collection handles
   * @param {object} options.sortOptions
   * note: sort options apply to each collection individually
   */
  getCollections({ collections, sortOptions, omitProducts }: {
    collections: Array<string>,
    sortOptions?: SortOptions,
    omitProducts?: boolean
  }) {
    return this._getCached(getCollections, {
      collections,
      sortOptions,
      omitProducts,
    })
  }

  //
  // content methods
  // nacelle DOES NOT guarantee schemas for any content item.
  // use `_getCachedContent` so we can dispatch specific mapping functions
  // depending on the data source.
  //

  private async _getCachedContent<T>(
    fn: Function,
    options: GetContentOptions & { handle: string } & T
  ) {
    const mapping = options.mapping || 'contentful'
    const namespace = options.namespace
    const data = await this._getCached(fn, {
      ...options,
      handle: (namespace) ? `${namespace}/${options.handle}` : options.handle
    })
    switch (mapping) {
      case 'contentful':
        return mapContent(data, options)
      case 'storyblok':
        return mapStoryContent(data)
    }
  }
  /**
   * @param {object} options
   * @param {string} options.handle
   * @param {string} options.locale
   */
  getBlog(options: GetItemOptions): Promise<BlogData> {
    return this._getCachedContent(getBlog, this._withDefaultLocale(options))
  }
  /**
   */
  async getBlogPage(options: GetBlogOptions) {
    const handles = this.getSelectedBlogList(options.blog, options.selectedList)
    const optionsResult = this._withDefaultLocale<GetBlogOptions>(options)
    const { paginate, itemsPerPage, startPoint } = optionsResult
    let responseHandles = []
    if (paginate && isNumber(itemsPerPage) && isNumber(startPoint)) {
      responseHandles = handles.slice(startPoint, startPoint + itemsPerPage)
    } else {
      responseHandles = handles
    }
    return Promise.all(
      responseHandles.map((handle: string) =>
        this.getArticle({
          blogHandle: options.blog.handle,
          handle,
          locale: optionsResult.locale
        })
      )
    )
  }
  /**
   * @param {object} options
   * @param {string} options.handle
   * @param {string} options.locale
   */
  getArticle(options: GetItemOptions & { blogHandle: string }) {
    return this._getCachedContent(getArticle, this._withDefaultLocale(options))
  }
  /**
   * @param {object} options
   * @param {string} options.handle
   * @param {string} options.locale
   * @param {string} options.fallback
   */
  async getPage(options: GetItemOptions & { fallback: string }) {
    let myOptions = this._withDefaultLocale(options)
    let result = null
    try {
      result = await this._getCachedContent(getPage, myOptions)
    } catch (err) {
      if (options.fallback) {
        myOptions.handle = options.fallback
        result = await this._getCachedContent(getPage, myOptions)
      } else {
        throw err
      }
    }
    return result
  }
  /**
   * @param {object} options
   * @param {string} options.handle
   * @param {string} options.locale
   */
  getContentItem(options: GetItemOptions) {
    return this._getCachedContent(getContentItem, this._withDefaultLocale(options))
  }
  // content getters
  getSelectedBlogList(blog: Blog, selectedList = 'default') {
    let articleList: ArticleList | any = {}
    if (
      blog &&
      blog.articleLists &&
      blog.articleLists.length
    ) {
      for (let list of blog.articleLists) {
        if (list.slug === selectedList) {
          articleList = list
          break
        }
      }
    }
    return (articleList && articleList.handles) ? articleList.handles : []
  }

  //
  // recos
  // @link https://help.rebuyengine.com/en/articles/6120502-hide-products-from-being-recommended
  //

  /**
   * simple wrapper around the axios call to rebuy api v1
   */
  private _rebuy(options: { endpoint: string, query: any }): Promise<Array<string>> {
    try {
      const excludedProductHandles = [ 'influencer-exclusive-order', 'engraving-fee', 'bracelet-sleeve-fee' ]
      const apiKey = process.env.REBUY_API_KEY
      if (apiKey && typeof apiKey === 'string') {
        // the rebuy API is a bit fragile, so let's cache the responses
        return this._dataLoaderCache(
          async (endpoint: string, query: any) => {
            let params = { ...query, key: apiKey }
            if (process.env.NODE_ENV !== 'production') {
              params.bust_cache = 'yes'
            }
            const res = await this.$axios(`https://rebuyengine.com/api/v1/${endpoint}`, {
              method: 'get',
              params
            })
            return get(res, 'data.data', [])
              .map(({ handle }: { handle: string }) => handle)
              .filter((handle: string) => !excludedProductHandles.includes(handle))
              .filter((handle: string) => !(handle.startsWith('kiosk-') || handle.startsWith('raw-')))
          },
          [options.endpoint, options.query],
          1000 // 5min
        )
      } else {
        throw new Error('missing REBUY_API_KEY!')
      }
    } catch (err) {
      console.error(err)
      return Promise.resolve([])
    }
  }
  /**
   * @link https://developers.rebuyengine.com/reference/top-sellers
   */
  async getRecosBestSelling({ products, limit }: { products?: Array<Product>, limit?: number } = {}) {
    const filteredProductIds = [
      '2629606310001', // influencer-exclusive-order
      '1652939784305', // engraving-fee
      '4375766990961', // bracelet-sleeve-fee
    ]
    let query: { limit: number, shopify_product_ids?: string, filter_oos: 'yes' } = {
      limit: limit || 5,
      filter_oos: 'yes'
    }
    // Comma separated list of Shopify Product IDs to filter from the result set.
    if (products && products.length) {
      let productIds = []
      for (let product of products) {
        const productId = get(product, 'pimSyncSourceProductId')
        productIds.push(parseId(productId))
      }
      query.shopify_product_ids = [ ...productIds, ...filteredProductIds ].join(',')
    } else {
      query.shopify_product_ids = [ ...filteredProductIds ].join(',')
    }
    const handles = await this._rebuy({ endpoint: 'products/top_sellers', query })
    return this.getProducts({ handles })
  }
  /**
   * @link https://developers.rebuyengine.com/reference/trending-products
   */
  async getRecosTrending({ limit }: { limit?: number } = {}) {
    const handles = await this._rebuy({
      endpoint: 'products/trending_products',
      query: {
        // omit these product ids from the results
        shopify_product_ids: [
          '2629606310001', // influencer-exclusive-order
          '1652939784305', // engraving-fee
          '4375766990961', // bracelet-sleeve-fee
        ].join(','),
        limit: limit || 5,
        filter_oos: 'yes',
      }
    })
    return this.getProducts({ handles })
  }
  /**
   * @link https://developers.rebuyengine.com/reference/similar-products
   */
  async getRecosByProduct({ product, limit }: { product: Product, limit: number }) {
    const productId = get(product, 'pimSyncSourceProductId')
    const handles = await this._rebuy({
      endpoint: 'products/similar_products',
      query: {
        shopify_product_ids: parseId(productId),
        limit: limit || 5
      }
    })
    return this.getProducts({ handles })
  }
  /**
   * @link https://developers.rebuyengine.com/reference/collections
   */
  async getRecosByCollection({ handle, handles, smartSort, limit }: {
    handle?: string,
    handles?: Array<string>,
    smartSort: 'yes' | 'top_sellers',
    limit: number
  }) {
    let collectionHandles = []
    if (handle) { collectionHandles.push(handle) }
    if (handles && handles.length) { collectionHandles.push(...handles) }
    const collections: Array<{ collection: Collection }> = await this.getCollections({
      collections: collectionHandles,
      omitProducts: true
    })
    const collectionIds = collections.map(({ collection }) => {
      const collectionId = get(collection, 'pimSyncSourceCollectionId')
      return parseId(collectionId)
    })
    console.log(collectionIds)
    const recoHandles = await this._rebuy({
      endpoint: 'products/collections',
      query: {
        shopify_collection_ids: collectionIds.join(','),
        smart_sort: smartSort || 'yes',
        limit: limit || 5,
        filter_oos: 'yes',
        // omit these product ids from the results
        shopify_product_ids: [
          '2629606310001', // influencer-exclusive-order
          '1652939784305', // engraving-fee
          '4375766990961', // bracelet-sleeve-fee
        ].join(','),
      }
    })
    if (recoHandles.length === 0) {
      console.warn(
        'products/collections returned an empty result!',
        'please contact rebuy support to re-sync the collection'
      )
    }
    return (recoHandles.length)
      ? { products: await this.getProducts({ handles: recoHandles }), collections }
      : { products: [], collections }
  }

  //
  // status checking
  //

  /**
   * @param {object} options
   * @param {object} [options.product]
   * @param {object} [options.variant]
   */
  async checkStockAvailable({ product, collection }: StockOptions): Promise<StockData | void> {
    let result = null
    if (product && product.handle) {
      try {
        const q = getProductVariantStock()
        const options = [{
          ...q,
          variables: { productHandle: product.handle }
        }]
        const ttl = 1000 * q.ttl // match the edge cache ttl
        const response = await this._gqlCache(graphql, options, ttl)
        result = get(response, 'data.result') || {}
      } catch (err) {
        console.error(err)
      }
    } else if (collection && collection.handle) {
      try {
        const q = getCollectionProductStock()
        const options = [{
          ...q,
          variables: { collectionHandle: collection.handle }
        }]
        const ttl = 1000 * q.ttl // match the edge cache ttl
        const response = await this._gqlCache(graphql, options, ttl)
        result = get(response, 'data.result') || {}
      } catch (err) {
        console.error(err)
      }
    }
    return result
  }
}
/**
 * define API export interface options
 */
export interface ApiClientOptions {
  $nacelle: any;
  store: Store<any>;
  $axios: AxiosInstance;
  defaultLocale: string;
}
export interface SortOptions {
}
// need a common way to pass a driver option from the context
// all content methods should extend the interface
export interface GetContentOptions {
  mapping?: 'storyblok' | 'contentful'
  namespace?: string
  locale?: string
}
export interface GetItemOptions extends GetContentOptions {
  handle: string;
}
export interface GetItemsOptions extends GetContentOptions {
  handles: Array<string>;
}
export interface GetCollectionOptions extends GetItemOptions {
  index?: number;
  selectedList?: string;
  sortOptions?: SortOptions;
  itemsPerPage?: number | boolean; // FIXME
  omitProducts?: boolean
}
export interface GetBlogOptions extends GetContentOptions {
  blog: Blog;
  selectedList?: string;
  startPoint?: number;
  paginate?: boolean;
  itemsPerPage?: number;
}
export interface StockOptions {
  product?: Product;
  collection?: Collection;
}
