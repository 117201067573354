import { dataWrapper, readCache, isValidHandle } from './utils'
// since we don't have a consistent mapping (and nacelle doesn't guarantee any
// shemas), data should be mapped in the api client.

const DEFAULT_LOCALE = 'en-us'

const contentDataWrapper = (
  $nacelle,
  method,
  options
) => {
  return (options && options.mapping === 'storyblok')
    ? dataWrapper($nacelle.storyblokData, method, options)
    : dataWrapper($nacelle.contentData, method, options)
}

/**
 * @param {object} options
 * @param {object} options.$nacelle
 */
export const getPage = (options) => {
  const { $nacelle, handle, mapping } = options
  const locale = options.locale || $nacelle.locale || DEFAULT_LOCALE
  if (isValidHandle(handle)) {
    return contentDataWrapper($nacelle, 'page', { handle, locale, mapping })
  }
}

export const getBlog = (options) => {
  const { $nacelle, handle, mapping } = options
  const locale = options.locale || $nacelle.locale || DEFAULT_LOCALE
  return contentDataWrapper($nacelle, 'blog', { handle, locale, mapping })
}

export const getArticle = (options) => {
  const { blogHandle, handle, $nacelle, mapping } = options
  const locale = options.locale || $nacelle.locale || DEFAULT_LOCALE
  if (isValidHandle(handle) && isValidHandle(blogHandle)) {
    return contentDataWrapper($nacelle, 'article', {
      handle: `${blogHandle}/${handle}`,
      locale,
      mapping
    })
  }
}

/**
 * @param {object} options
 * @param {object} options.$nacelle
 * @param {string} options.handle
 * @param {string} options.type
 * @param {string} [options.locale]
 */
export const getContentItem = async (options) => {
  const { $nacelle, handle, type } = options
  const locale = options.locale || $nacelle.locale || DEFAULT_LOCALE
  return contentDataWrapper($nacelle, 'content', {
    handle,
    locale,
    type
  })
}
