
/**
 * TikTok Events Plugin
 *
 * subscribes to the events module and pushed the aproprate data to the TikTok pixel
 * see store/events.js for a list of supported events.
 *
 * @param store
 */
export default (store)=> {
  const eventsHandler = (mutation) => {
    // console.log(mutation.type,mutation)
    if (window.ttq && mutation.type == 'search/setQuery') {
      window.ttq.track('Search', mutation.payload.value)
    }
    if (mutation.type === 'events/addEvent') {
      const { eventType, payload } = mutation.payload
      // console.log(`[TikTok] events/${eventType}`, payload)
      switch (eventType) {
      case 'PAGE_VIEW':
        // console.log('PAGE_VIEW')
        if (window.ttq) {
          window.ttq.track('ViewContent')
        }
        break
      case 'ADD_TO_CART':
        if (window.ttq && payload && payload.product && payload.product.variant) {
          // console.log('ADD_TO_CART', payload.product.variant)
          window.ttq.track('AddToCart', {
            content_id: payload.product.variant.id,
            content_type: 'product',
            content_name: payload.product.variant.title,
            quantity: payload.product.quantity,
            price: payload.product.variant.price,
            value: payload.product.variant.price * payload.product.quantity,
            currency: payload.product.variant.priceCurrency,
          })
        }
        break
      case 'CHECKOUT_INIT':
        if (window.ttq) {
          window.ttq.track('InitiateCheckout');
        }
        break
      }
    }
  }
  if (!process.server) {
    window.dataLayer = window.dataLayer || []
    store.subscribe((mutation, payload) => {
      try {
        eventsHandler(mutation, payload)
      } catch (err) {
        console.error('[tiktok-events-plugin]', err)
      }
    })
  }
}
