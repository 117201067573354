export default () => {
  if (typeof window !== 'undefined') {
    const _hjSettings = {
      hjid: 2027408,
      hjsv: 6
    }

    window._hjSettings = _hjSettings

    window.hj = function() {
      ;(window.hj.q = window.hj.q || []).push(arguments)
    }

    const rootScript = document.getElementsByTagName('script')[0]
    const hotjarScript = document.createElement('script')
    hotjarScript.type = 'text/javascript'
    hotjarScript.defer = true
    hotjarScript.src = `https://static.hotjar.com/c/hotjar-${_hjSettings.hjid}.js?sv=${_hjSettings.hjsv}`
    rootScript.parentNode.insertBefore(hotjarScript, rootScript)
  }
}
