import NacelleClient, {
  FetchContentParams,
  FetchPageParams,
  FetchPagesParams,
  FetchArticleParams,
  FetchArticlesParams,
  FetchBlogParams,
  NacelleContent,
  NacelleGraphQLConnector,
  NacelleGraphQLConnectorParams
} from '@nacelle/client-js-sdk'
import { createClient, CreateClientParams } from 'contentful'
import EntriesQuery from '../interfaces/EntriesQuery'
import Entry from '../interfaces/Entry'
import mapContentfulEntry from '../utils/mapContentfulEntry'
import mapLocale from '../utils/mapLocale'

export interface ContentfulClientParams
  extends Omit<CreateClientParams, 'host'> {
  host?: string
}

export interface NacelleContentfulPreviewConnectorParams
  extends NacelleGraphQLConnectorParams {
  contentfulConfig: ContentfulClientParams
  contentfulPreviewLocales?: string[]
  include?: number
  client?: object
  entryMapper?: (entry: Entry) => NacelleContent
}

export interface CreateConnectorOptions {
  contentfulConfig: ContentfulClientParams
  contentfulPreviewLocales?: string[]
  include?: number
  client?: object
  entryMapper?: (entry: Entry) => NacelleContent
}

export function createContentfulPreviewConnector(
  nacelleClient: NacelleClient,
  options: CreateConnectorOptions
): NacelleContentfulPreviewConnector {
  const params = {
    ...options,
    endpoint: nacelleClient.nacelleEndpoint,
    spaceId: nacelleClient.id,
    token: nacelleClient.token
  }
  return new NacelleContentfulPreviewConnector(params)
}

export default class NacelleContentfulPreviewConnector extends NacelleGraphQLConnector {
  contentfulConfig: CreateClientParams
  contentfulPreviewLocales: string[]
  contentfulClient: any
  contentfulIncludeDepth: number
  entryMapper: (entry: Entry) => NacelleContent

  constructor(params: NacelleContentfulPreviewConnectorParams) {
    super(params)

    this.contentfulConfig = params.contentfulConfig
    const clientConfig = {
      ...this.contentfulConfig,
      host: this.contentfulConfig.host || 'preview.contentful.com'
    }
    this.contentfulClient = params.client || createClient(clientConfig)
    this.contentfulPreviewLocales = params.contentfulPreviewLocales || ['en-US']
    this.contentfulIncludeDepth = params.include || 3
    this.entryMapper = params.entryMapper || mapContentfulEntry
  }

  // Override content methods
  async content({
    handle,
    type = 'page',
    locale,
    blogHandle
  }: FetchContentParams): Promise<NacelleContent> {
    const useLocale = locale
      ? mapLocale(locale, this.contentfulPreviewLocales)
      : 'en-US'

    const query: EntriesQuery = {
      'fields.handle': handle,
      content_type: type,
      locale: useLocale,
      include: this.contentfulIncludeDepth
    }

    if (type === 'article' && blogHandle) {
      query['fields.blogHandle'] = blogHandle
    }

    const response = await this.contentfulClient.getEntries(query)

    if (response && response.items.length > 0) {
      return this.entryMapper(response.items[0])
    }

    throw new Error(
      `Unable to find Contentful preview content with handle ${handle}`
    )
  }

  async allContent(): Promise<NacelleContent[]> {
    const response = await this.contentfulClient.getEntries({
      locale: this.contentfulPreviewLocales[0],
      include: this.contentfulIncludeDepth
    })

    if (response && response.items.length > 0) {
      return response.items.map(this.entryMapper)
    }

    return []
  }

  page({ handle, locale }: FetchPageParams): Promise<NacelleContent> {
    return this.content({
      handle,
      locale,
      type: 'page'
    })
  }

  async pages({
    handles,
    locale
  }: FetchPagesParams): Promise<NacelleContent[]> {
    const requests = handles.map((handle: string) => {
      return this.content({
        handle,
        locale,
        type: 'page'
      })
    })
    const results = (await Promise.all(
      requests.map((p: Promise<NacelleContent>) => p.catch((e) => e))
    )) as Array<NacelleContent | Error>
    const validResults = results.filter(
      (result) => !(result instanceof Error)
    ) as NacelleContent[]

    return validResults
  }

  article({
    handle,
    locale,
    blogHandle
  }: FetchArticleParams): Promise<NacelleContent> {
    return this.content({
      handle,
      locale,
      blogHandle,
      type: 'article'
    })
  }

  async articles({
    handles,
    blogHandle,
    locale
  }: FetchArticlesParams): Promise<NacelleContent[]> {
    const requests = handles.map((handle: string) => {
      return this.content({
        handle,
        blogHandle,
        locale,
        type: 'article'
      })
    })
    const results = (await Promise.all(
      requests.map((p: Promise<NacelleContent>) => p.catch((e) => e))
    )) as Array<NacelleContent | Error>
    const validResults = results.filter(
      (result) => !(result instanceof Error)
    ) as NacelleContent[]

    return validResults
  }

  blog({ handle, locale }: FetchBlogParams): Promise<NacelleContent> {
    return this.content({
      handle,
      locale: locale,
      type: 'blog'
    })
  }
}
