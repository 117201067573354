import isString from 'lodash/isString'
export const ENABLE_NACELLE_CACHE = (
  (process.server && !process.env.DISABLE_NACELLE_CACHE) ||
  (process.env.NODE_ENV === 'test')
)
export const isValidHandle = (handle: any) => (
  isString(handle) &&
  handle &&
  (handle !== 'null') &&
  (handle !== 'undefined') &&
  !handle.includes('/null')
)
/**
 * async wrapper around fs.readFile
 * @param {string} fileName
 */
export const readFileAsync = (fileName: string) => {
  const fs = require('fs')
  return new Promise((resolve, reject) => {
    fs.readFile(fileName, 'utf8', (err: any, data: string) => {
      err ? reject(err) : resolve(data)
    })
  })
}
interface ResourceId {
  handle: string
  locale: string
  type?: string
}
const getResourcePath = (db: string, {
  handle,
  locale,
  type,
  customPrefix = '/'
}: ResourceId & { customPrefix?: string }) => {
  const dbMap = {
    page: 'pages',
    collection: 'collections',
    product: 'products',
    blog: 'blogs',
    article: 'articles',
  }
  // @ts-ignore
  const dbName = dbMap[db] || db
  const prefix = `${customPrefix}data/${dbName}`
  let keys = [ handle, locale ]
  if (type) {
    keys.push(type)
  }
  const itemKey = keys.join('--')
  return `${prefix}/${itemKey}/static.json`
}
/**
 * read cached json data, for test purposes. the sdk response is way too
 * slow.
 */
export const readCache = async (db: string, {
  handle,
  locale,
  type,
}: ResourceId) => {
  let result = null
  try {
    const path = getResourcePath(db, { handle, locale, type, customPrefix: 'static/' })
    const file = await readFileAsync(path)
    // @ts-ignore
    result = JSON.parse(file)
  } catch (err) {
    //console.error('CACHE MISS', { handle, type, locale, err })
    throw new Error(`CacheMiss<${db}/${handle}>`)
  }
  return result
}
const fetchStatic = async (db: string, {
  handle,
  locale,
  type
}: ResourceId) => {
  const response = await fetch(getResourcePath(db, { handle, locale, type }))
  if (response.status == 200) {
    return response.json()
  } else if (process.env.NODE_ENV !== 'production') {
    // console.warn('fetchStatic', response.status, db, { handle, locale, type })
    throw new Error(`StaticAPICacheMiss<${db}/${handle}>`)
  }
}
/**
 * check `~/modules/nacelle/index.ts` for details on how the static api / disk
 * cache are generated.
 *
 * @param {object} $nacelle
 * @param {string} method
 * @param {object} sdkOptions
 */
export const dataWrapper = async (
  $nacelleData: any,
  method: string,
  { handle, locale, type, mapping }: ResourceId & { mapping: string }
) => {
  const previewMethods = [ 'page', 'blog', 'article', ]
  let result = null
  try {
    if (ENABLE_NACELLE_CACHE) {
      // read from disk cache for server-side render
      result = await readCache(method, { handle, type, locale })
    } else if (
      process.env.NACELLE_PREVIEW_MODE &&
      process.client &&
      previewMethods.includes(method)
    ) {
      // use nacelle sdk for previews
      if (method === 'article') {
        const [ blogHandle, articleHandle ] = handle.split('/')
        result = await $nacelleData[method]({ handle: articleHandle, blogHandle, type, locale })
      } else {
        result = await $nacelleData[method]({ handle, type, locale })
      }
    } else {
      // fetch from static api on the client!
      result = await fetchStatic(method, { handle, type, locale })
    }
  } catch (err) {
    /*
    if (
      // @ts-ignore
      ['test', 'development'].includes(process.env.NODE_ENV)
    ) {
      console.warn(`dataWrapper<${method}>`, handle, { locale, type, mapping }, err)
    }
    */
    throw err
  }
  return result
}
