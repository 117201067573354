export default () => {
  const ATTENTIVE_DOMAIN = process.env.ATTENTIVE_DOMAIN
  if ((process.browser || process.client) && ATTENTIVE_DOMAIN) {
    window.__attentive_domain = ATTENTIVE_DOMAIN;
    (function () {
      window.__attentive = {invoked:!1,show:function () {
        this.invoked = !0
      }};var c = !1,a = function () {
        if (document.readyState && !/loaded|complete/.test(document.readyState)) return void setTimeout(a,10);if (!c) return c = !0,void setTimeout(a,50);var b = document.createElement("script");b.setAttribute("async","true");b.type = "text/javascript";b.src = "https://cdn.attn.tv/loader.js";((document.getElementsByTagName("head") || [null])[0] || document.getElementsByTagName("script")[0].parentNode).appendChild(b)
      };
      // eslint-disable-next-line no-nested-ternary
      document.readyState && /loaded|complete/.test(document.readyState) ? a() : window.addEventListener ? window.addEventListener("load",a,!1) : window.attachEvent("onload",a)
    })();window.__attentive.show();
  }
}
