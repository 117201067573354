import { createContentfulPreviewConnector } from '@nacelle/contentful-preview-connector'
/**
 * as of v2 the preview function is handled on the space ONLY IF YOU ARE USING
 * THE NACELLE GRAPHQL API DIRECTLY.
 *
 * see Space Settings > API Details > Create New Key
 *
 * use the generated key in place of your normal access token for preview environments
 * @link https://docs.nacelle.com/docs/monolithic-connectors#contentful-preview-data
 *
 * this preview plugin IS STILL REQUIRED for content previews.
 * @link https://www.npmjs.com/package/@nacelle/contentful-preview-connector
 */
export default ({ app }) => {
  if (process.env.NACELLE_PREVIEW_MODE && process.client) {
    const contentfulSpaceID = process.env.NACELLE_CMS_PREVIEW_SPACE_ID
    const contentfulToken = process.env.NACELLE_CMS_PREVIEW_TOKEN
    const contentfulIncludeDepth = 6
    // Checks .env file for proper config variables
    if (!process.env.NACELLE_CMS_PREVIEW_TOKEN) {
      throw new Error(
        "Couldn't get data from your CMS. Make sure to include NACELLE_CMS_PREVIEW_TOKEN in your .env file"
      )
    }
    if (!process.env.NACELLE_CMS_PREVIEW_SPACE_ID) {
      throw new Error(
        "Couldn't get data from your CMS. Make sure to include NACELLE_CMS_PREVIEW_SPACE_ID in your .env file"
      )
    }
    let connector = createContentfulPreviewConnector(app.$nacelle.contentClient, {
      // @link https://github.com/contentful/contentful.js#configuration
      contentfulConfig: {
        space: contentfulSpaceID,
        accessToken: contentfulToken,
      },
      contentfulPreviewLocales: ['en-US'],
      include: contentfulIncludeDepth,
    })
    // fix the broken request object
    connector.apiClient.options.headers['Content-Type'] = 'application/json'
    // Initialize the Contentful Preview Connector on our special content-only
    // client
    app.$nacelle.contentData.update({ connector })
    /*
    console.log('PREVIEW MODE ACTIVE', {
      contentfulSpaceID: !!contentfulSpaceID,
      contentfulToken: !!contentfulToken,
      contentfulIncludeDepth,
    })
    */
  }
}
