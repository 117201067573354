const loadProfile = (store) => {
  const defaultProfile = process.env.CART_DEFAULT_PROFILE
  const cartProfileHandle = defaultProfile || store.getters['space/getMetafield']('cart', 'profile')
  if (cartProfileHandle) {
    store.dispatch('cart/loadProfile', cartProfileHandle)
      .catch(err => console.error(err))
    store.dispatch('braceletBuilder/loadPage')
      .catch(err => console.error(err))
  } else {
    // console.warn('No cart profile defined!')
  }
}
const openCart = (store, route) => {
  setTimeout(()=>{
    if (route.query.openCart) {
      //console.log("Showing the Cart Right here")
      store.commit('cart/showCart')
    }
  }, 1000)
}
export default function ({ store, route }) {
  store.commit('cart/hideCart')
  loadProfile(store)
  openCart(store, route)
}
