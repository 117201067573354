import flow from 'lodash/fp/flow'
import uniq from 'lodash/uniq'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import localforage from 'localforage'
import range from 'lodash/range'
import { processBundles } from '~/utils/bundleHelper'
import { mapProducts } from '@/utils/shopifyHelper'
import {
  mergeProps,
  getItems,
  getLineItemIndex,
  getLineItemId
} from '~/utils/cartHelper'

const allowedCheckoutMetafields = []

/**
 * these are used to set up the initial reactive state of this store module
 * so that config options pulled in from contentful are able to update and
 * control the state and behavior of the application.
 */
const profileKeys = [
  'appliedDiscounts',
  'auto1015',
  'auto1015Banner',
  'bannerCartMessage',
  'bogo',
  'bogoCustomerBuysCollections',
  'bogoCustomerBuysQuantity',
  'bogoCustomerBuysThreshold',
  'bogoCustomerGetsCollections',
  'bogoCustomerGetsDiscountDecimal',
  'bogoCustomerGetsQuantity',
  'bogoMessage',
  'bogoUsesPerOrderLimit',
  'emptyCartMessage',
  'engravingFeeProduct',
  'freeShippingBackgroundColor',
  'freeShippingMessage',
  'freeShippingProgressColor',
  'freeShippingReachedMessage',
  'freeShippingTextColor',
  'freeShippingThreshold',
  'giftWithPurchase',
  'giftWithPurchaseAuto',
  'giftWithPurchaseCollection',
  'giftWithPurchaseCta',
  'giftWithPurchaseFallbackCollections',
  'giftWithPurchaseFallbackMessage',
  'giftWithPurchaseMessage',
  'giftWithPurchaseSuccess',
  'giftWithPurchaseThreshold',
  'hiddenLineItemHandles',
  'includeFreeShippingProgress',
  'modBraceletSleeveFeeProduct',
  'profileHandle',
  'siteWideSale',
  'siteWideSaleMessage',
  'tieredDiscount',
  'upsellCollection',
  'engravingUpsell',
  'engravingUpsellPercentDiscount'
]

const lineProps = {
  giftWithPurchase: '_lineGiftWithPurchase'
}

export const state = () => {
  let result = {
    cartVisible: false,
    error: null,
    freeShippingThreshold: null,
    giftWithPurchaseCollectionOutOfStock: false,
    lineItems: [],
    engravingUpsell: false,
    engravingUpsellPercentDiscount: 0,
    redoApplied: true,
    redoEnabled: true,
  }
  for (let key of profileKeys) {
    result[key] = null
  }
  return result
}

const productHasDiscount = ({
  item,
  appliedDiscounts
}) => {
  let selectorMatch = false
  let selector
  let appliedDiscount
  for (let discount of (appliedDiscounts || [])) {
    const {
      tieredDiscountSelectorType,
      tieredDiscountSelectorMatchType,
      tieredDiscountSelector,
    } = discount
    switch (tieredDiscountSelectorType) {
    case 'tag':
      if (
        !item.tags.includes('clearance') &&
        item.tags.includes(tieredDiscountSelector) &&
        tieredDiscountSelectorMatchType === 'include'
      ) {
        selectorMatch = true
        selector = tieredDiscountSelector
        appliedDiscount = discount
      } else if (tieredDiscountSelectorMatchType === 'exclude') {
        selectorMatch = true
        selector = tieredDiscountSelector
        appliedDiscount = discount
      }
    }
  }
  if (selectorMatch) {
    return [
      selector,
      range(item.quantity).map(() => item.id),
      appliedDiscount
    ]
  } else {
    return null
  }
}

const getCheckoutLineItems = (lineItems) => {
  const ENABLE_SIMPLE_BUNDLE_DATA = process.env.ENABLE_SIMPLE_BUNDLE_DATA
  let result = []
  if (lineItems.length > 0) {
    for (let lineItem of lineItems) {
      let checkoutLineItem = {
        cartItemId: lineItem.id || getLineItemId(lineItem),
        variantId: lineItem.variant.id,
        quantity: lineItem.quantity,
        metafields: []
      }
      // accentuate metafields hold some additional data that nacelle doesn't
      // accept, strip that here before its sent to them.
      for (let { key, value } of lineItem.metafields) {
        if (allowedCheckoutMetafields.includes(key)) {
          checkoutLineItem.metafields.push({ key, value })
        }
      }
      for (let { key, value } of (lineItem.customAttributes || [])) {
        if (
          (key && key.startsWith('_local')) ||
          (!ENABLE_SIMPLE_BUNDLE_DATA && key && (key === '_configurator_data'))
        ) {
          continue // omit key
        } else {
          if (isNumber(value) || isString(value)) {
            checkoutLineItem.metafields.push({ key, value })
          } else if (isBoolean(value)) {
            checkoutLineItem.metafields.push({
              key,
              value: (value) ? 'true' : 'false'
            })
          } else if (isArray(value) || isObject(value)) {
            checkoutLineItem.metafields.push({
              key,
              value: JSON.stringify(value)
            })
          } else if (value === undefined) {
            checkoutLineItem.metafields.push({ key, value: 'true' })
          }
        }
      }
      result.push(checkoutLineItem)
    }
  }
  return result
}

export const getters = {
  productsWithDiscount({
    appliedDiscounts,
    bogoCustomerGetsDiscountDecimal,
  }) {
    return (lineItems) => {
      let result = []
      let bogoContext = []
      let context = {}
      for (let item of (lineItems || [])) {
        let hasBogo = null
        if (item && item.customAttributes) {
          hasBogo = item.customAttributes.find(({ key }) => {
            return key && key.includes('_bogo')
          })
        }
        if (hasBogo) {
          bogoContext.push({ ...hasBogo, id: item.id })
        } else {
          const hasDiscount = productHasDiscount({ item, appliedDiscounts })
          if (hasDiscount) {
            const [ selector, lineItemIds, discount ] = hasDiscount
            if (!context[selector]) {
              context[selector] = {
                ids: [],
                discount
              }
            }
            context[selector].ids.push(...lineItemIds)
          }
        }
      }
      for (let lineItem of (lineItems || [])) {
        let price
        let compareAtPrice
        let discountApplied
        if (Object.keys(context).length) {
          for (let { ids, discount } of Object.values(context)) {
            if (ids.includes(lineItem.id)) {
              for (let tier of discount.tieredDiscountTiers) {
                const {
                  quantity,
                  discountType,
                  discountAmount
                } = tier
                if (ids.length >= quantity) {
                  let amountOff = 0
                  switch (discountType) {
                  case 'percent':
                    amountOff = (
                      parseFloat(discountAmount) * lineItem.variant.price
                    )
                    break
                  case 'dollar-amount':
                    amountOff = parseFloat(discountAmount)
                    break
                  }
                  price = (lineItem.variant.price - amountOff).toFixed(2)
                  compareAtPrice = lineItem.variant.price
                  discountApplied = { ...discount, tier }
                }
              }
              break
            }
          }
        }
        if (bogoContext.length) {
          for (let bogo of bogoContext) {
            // console.log('bogoContext', bogo)
            if (lineItem.id === bogo.id) {
              // attach a little more context to the line item
              // product.variant.price * discountAmount * quantity
              const discountAmount = bogoCustomerGetsDiscountDecimal
              const amountOff = (
                lineItem.variant.price * discountAmount * bogo.value
              )
              discountApplied = {
                tier: {
                  quantity: bogo.value,
                  discountType: 'bogo',
                  discountAmount,
                  amountOff
                }
              }
            }
          }
        }
        result.push({
          ...lineItem,
          discountApplied,
          variant: {
            ...lineItem.variant,
            price: price || lineItem.variant.price,
            compareAtPrice: (
              compareAtPrice || lineItem.variant.compareAtPrice
            )
          }
        })
      }
      return result
    }
  },
  giftWithPurchaseCollection(state) {
    const gwpEnabled = state.giftWithPurchase
    const primaryOutOfStock = state.giftWithPurchaseCollectionOutOfStock
    const fallbackCollectionNames = state.giftWithPurchaseFallbackCollections
    let collectionName = null
    if (gwpEnabled) {
      if (primaryOutOfStock) {
        if (
          isArray(fallbackCollectionNames) &&
          fallbackCollectionNames.length
        ) {
          // really only supporting one fallback collection for now, the logic for
          // falling back is still tbd
          collectionName = fallbackCollectionNames[0]
        }
      } else {
        collectionName = state.giftWithPurchaseCollection
      }
    }
    return collectionName
  },
  productHasGiftWithPurchase() {
    return (product, allowAddon = false) => {
      const lineItemProperties = get(product, 'customAttributes')
      let result = false
      let hits = 0
      if (isArray(lineItemProperties)) {
        for (let { key } of lineItemProperties) {
          if (key === lineProps.giftWithPurchase) {
            hits += 1
          }
          if (key === '_local_addon_gwp' && !allowAddon) {
            hits -= 1
          }
        }
      }
      result = hits > 0
      return result
    }
  },
  activeOfferComponent(state) {
    const offerPrecedence = [
      'siteWideSale',
      'giftWithPurchase',
      'bogo',
      'auto1015'
    ]
    const availableOffers = {
      siteWideSale: state.siteWideSale,
      giftWithPurchase: state.giftWithPurchase,
      bogo: state.bogo,
      auto1015: state.auto1015
    }
    let result = null
    if (!availableOffers['siteWideSale']) {
      for (let key of offerPrecedence) {
        if (availableOffers[key] === true) {
          switch (key) {
          case 'giftWithPurchase':
            result = 'CartOfferGiftWithPurchase'
            break
          case 'bogo':
            result = 'CartOfferBogo'
            break
          case 'auto1015':
            result = 'CartOfferAuto1015'
            break
          }
        }
      }
    }
    return result
  },
  activeOfferProps(state, getters) {
    // getter subs
    const component = getters.activeOfferComponent
    const cartSubtotal = getters.cartSubtotal

    // -- gwp
    const gwpProps = {
      autoAdd: state.giftWithPurchaseAuto,
      cartSubtotal,
      collectionName: state.giftWithPurchaseCollection,
      cta: state.giftWithPurchaseCta,
      fallbackCollectionNames: state.giftWithPurchaseFallbackCollections,
      fallbackMessage: state.giftWithPurchaseFallbackMessage,
      message: state.giftWithPurchaseMessage,
      successMessage: state.giftWithPurchaseSuccess,
      threshold: state.giftWithPurchaseThreshold,
    }
    // -- bogo
    const bogoProps = {
      buysCollections: state.bogoCustomerBuysCollections,
      buysQuantity: state.bogoCustomerBuysQuantity,
      buysThreshold: state.bogoCustomerBuysThreshold,
      getsCollections: state.bogoCustomerGetsCollections,
      discount: state.bogoCustomerGetsDiscountDecimal,
      message: state.bogoMessage,
      usesPerOrderLimit: state.bogoUsesPerOrderLimit,
    }
    // -- auto 10-15
    const auto1015Props = {
      message: state.auto1015Message,
    }

    // props mapping
    let result = null
    switch (component) {
    case 'CartOfferGiftWithPurchase':
      result = { ...gwpProps }
      break
    case 'CartOfferBogo':
      result = { ...bogoProps }
      break
    case 'CartOfferAuto1015':
      result = { ...auto1015Props }
      break
    }
    return result
  },
  hiddenLineItemHandles(state) {
    const defaultHiddenLineItems = [
      'engraving-fee',
      'sleeve-fee',
      'bracelet-sleeve-fee',
      'labor-charge',
      // 'free-unlimited-return'
    ]
    const hiddenLineItemHandles = state.hiddenLineItemHandles
    return uniq([
      hiddenLineItemHandles || [],
      defaultHiddenLineItems
    ].flat())
  },
  quantityTotal(state, getters) {
    const CART_DEBUG_MODE = get(process.client ? window : null, '$nuxt.$config.CART_DEBUG_MODE')
    const lineItems = state.lineItems
    const hiddenLineItemHandles = getters.hiddenLineItemHandles
    let result = 0
    if (lineItems.length >= 1) {
      if (
        isArray(hiddenLineItemHandles) &&
        (!CART_DEBUG_MODE || (process.env.NODE_ENV === 'test'))
      ) {
        for (let item of lineItems) {
          if (!hiddenLineItemHandles.includes(item.handle)) {
            result += item.quantity
          }
        }
      } else {
        console.warn('CART_DEBUG_MODE active')
        for (let item of lineItems) {
          result += item.quantity
        }
      }
    }
    return result
  },
  cartSubtotal(state, { mappedLineItems }, rootState) {
    let result = 0
    if (mappedLineItems.length >= 1) {
      for (let item of mappedLineItems) {
        let skipItem = false
        if (item.customAttributes) {
          for (let { key } of item.customAttributes) {
            if (lineProps.giftWithPurchase === key) {
              skipItem = true
              break
            }
          }
        }
        /* Turn off Redo */
        /* if (rootState.redo.exchange) {
          if (item.handle === 'free-unlimited-return') {
            skipItem = true
          }
        } */
        if (!skipItem) {
          result += item.variant.price * item.quantity
        }

        // apply engraving discount
        let engravingDiscount = item.customAttributes ? item.customAttributes.find((obj)=>{
          return obj.key == '_engravingDiscount'
        }) : false;
        if (item.handle == "engraving-fee" && engravingDiscount) {
          result -= Math.ceil(Number(engravingDiscount.value) * item.variant.price)  * item.quantity
        }

        // apply bogo discount
        if (
          item.discountApplied &&
          item.discountApplied.tier &&
          (item.discountApplied.tier.discountType === 'bogo') &&
          item.discountApplied.tier.amountOff
        ) {
          // console.log('cartSubtotal', item.discountApplied)
          const { tier } = item.discountApplied
          result -= tier.amountOff
        }

      }
    }
    return result
  },
  freeShippingThresholdPassed(state, getters) {
    return !!(
      getters.cartSubtotal &&
      state.freeShippingThreshold &&
      getters.cartSubtotal > state.freeShippingThreshold
    )
  },
  amountUntilFreeShipping(state, getters) {
    if (getters.cartSubtotal != null && state.freeShippingThreshold) {
      return state.freeShippingThreshold - getters.cartSubtotal
    }
  },
  mappedLineItems({ lineItems }, { productsWithDiscount }) {
    const mapProductsWithDiscount = flow(
      mapProducts,
      productsWithDiscount
    )
    try {
      return mapProductsWithDiscount(lineItems)
    } catch (err) {
      console.error(lineItems, err)
    }
  },
  checkoutLineItems({ lineItems }) {
    return getCheckoutLineItems(lineItems)
  }
}

export const mutations = {
  gwpCollectionOutOfStock(state, payload) {
    state.giftWithPurchaseCollectionOutOfStock = !!payload
  },
  addLineItemMutation(state, payload) {
    let index = -1
    if (payload.id) {
      index = getLineItemIndex(state.lineItems, payload)
    }
    if (index === -1) {
      payload.id = getLineItemId(payload)
      state.lineItems.push({
        ...payload,
        quantity: payload.quantity || 1
      })
    } else {
      state.lineItems[index].quantity += (payload.quantity || 1)
    }
  },
  removeLineItemMutation(state, payload) {
    const index = getLineItemIndex(state.lineItems, payload)
    if (index > -1) {
      state.lineItems.splice(index, 1)
    }
  },
  incrementLineItemMutation(state, payload) {
    const index = getLineItemIndex(state.lineItems, payload)
    // console.log('cart/incr', index, payload)
    if (index > -1) {
      state.lineItems[index].quantity++
    }
  },
  decrementLineItemMutation(state, payload) {
    const index = getLineItemIndex(state.lineItems, payload)
    // console.log('cart/decr', index, payload)
    if (index !== -1 && state.lineItems[index].quantity >= 1) {
      state.lineItems[index].quantity--
      if (state.lineItems[index].quantity === 0) {
        state.lineItems.splice(index, 1)
      }
    }
  },
  setQuantityMutation(state, { id, quantity }) {
    const index = getLineItemIndex(state.lineItems, id)
    if (index > -1) {
      state.lineItems[index].quantity = quantity
    }
  },
  addLineItemWithProps(state, payload) {
    const { item, props } = payload
    const index = getLineItemIndex(state.lineItems, item)
    if (index === -1) {
      item.id = getLineItemId(item)
      state.lineItems.push({
        ...item,
        customAttributes: mergeProps(item, props),
        quantity: payload.quantity || 1
      })
    } else {
      state.lineItems[index].quantity += (payload.quantity || 1)
    }
  },
  /**
   * set a line item property for a particular line item
   * ```
   * this.$store.commit('cart/setLineItemProperty', {
   *   id: item.id,
   *   prop: { key: 'myProperty', value: 'myPropertyValue' }
   * })
   * ```
   *
   * @param {object} state
   * @param {{ id: String, prop: { key: String, value: String } }} payload
   * @link https://shopify.github.io/js-buy-sdk/#adding-line-items
   */
  setLineItemProperty(state, payload) {
    const index = getLineItemIndex(state.lineItems, payload)
    if (index > -1) {
      let props = []
      if (payload.props && payload.props.length) {
        props.push(...payload.props)
      } else if (payload.prop) {
        props.push(payload.prop)
      }
      state.lineItems[index] = {
        ...state.lineItems[index],
        customAttributes: mergeProps(
          state.lineItems[index],
          props
        )
      }
      state.lineItems = [ ...state.lineItems ]
    }
  },
  removeLineItemProperties(state, payload) {
    const { id, keys } = payload
    const index = getLineItemIndex(state.lineItems, id)
    if (index > -1) {
      let result = []
      if (isArray(state.lineItems[index].customAttributes)) {
        for (let { key, value } of state.lineItems[index].customAttributes) {
          if (!keys.includes(key)) {
            result.push({ key, value })
          }
        }
      }
      state.lineItems[index] = {
        ...state.lineItems[index],
        customAttributes: result
      }
      state.lineItems = [ ...state.lineItems ]
    }
  },
  setLineItems(state, payload) {
    state.lineItems.splice(0)
    state.lineItems = payload
  },
  showCart(state) {
    state.cartVisible = true
  },
  hideCart(state) {
    state.cartVisible = false
  },
  toggleCart(state) {
    state.cartVisible = !state.cartVisible
  },
  addRedoToCart(state) {
    state.redoApplied = true
  },
  removeRedoFromCart(state) {
    state.redoApplied = false
  },
  enableRedo(state) {
    state.redoEnabled = true
  },
  disableRedo(state) {
    state.redoEnabled = false
  },
  setFreeShippingThreshold(state, payload) {
    state.freeShippingThreshold = payload
  },
  setCartError(state, error) {
    state.error = error
  },
  setCartProfile(state, payload) {
    const mergedProfileEntries = [
      ...Object.entries(payload || {}),
      ...Object.entries(get(payload, 'activePromotion.fields') || {})
    ]
    let mergedProfile = {}
    //console.log('setCartProfile:input', { payload, mergedProfileEntries })
    try {
      for (let [key, value] of mergedProfileEntries) {
        if (profileKeys.includes(key)) {
          mergedProfile[key] = getItems(value)
        }
      }
      // console.log('setCartProfile:merged', mergedProfile)
      Object.assign(state, mergedProfile)
      // console.log('setCartProfile:final', state)
    } catch (err) {
      console.error(err)
    }
  }
}

export const actions = {
  async checkoutLineItems({ state }) {
    const lineItems = (process.env.ENABLE_SIMPLE_BUNDLE_DATA)
      ? state.lineItems
      : await processBundles(this.$api, state.lineItems)
    return getCheckoutLineItems(lineItems)
  },
  /**
   * if variant is specified, return the stock status only for that variant.
   * @param {object} product
   * @param {object} [variant]
   */
  async checkStockAvailable(ctx, { product, variant }) {
    const stockData = await this.$api.checkStockAvailable({ product })
    let result = null
    if (variant && variant.id) {
      result = get(
        stockData,
        `[${variant.id}].availableForSale`
      ) 
      console.log('variant available', { id: variant.id, variant, result, stockData })
      result = result || false
    } else {
      result = stockData
    }
    return result
  },
  async toggleCart({ state, rootState, commit, dispatch }) {
    commit('toggleCart')
    if (rootState.events) {
      dispatch(
        'events/toggleCart',
        {
          cartVisible: state.cartVisible,
          cart: state.lineItems
        },
        { root: true }
      )
    }
  },
  async showCart({ state, rootState, commit, dispatch }) {
    commit('showCart')
    if (rootState.events) {
      dispatch(
        'events/toggleCart',
        {
          cartVisible: state.cartVisible,
          cart: state.lineItems
        },
        { root: true }
      )
    }
  },
  async hideCart({ state, rootState, commit, dispatch }) {
    commit('hideCart')
    if (rootState.events) {
      dispatch(
        'events/toggleCart',
        {
          cartVisible: state.cartVisible,
          cart: state.lineItems
        },
        { root: true }
      )
    }
  },
  async addLineItem({ state, rootState, commit, dispatch }, payload) {
    commit('addLineItemMutation', payload)
    await dispatch('saveLineItems', state.lineItems)
    const lineItem = state.lineItems[state.lineItems.length - 1]
    if (rootState.events) {
      dispatch(
        'events/addToCart',
        {
          product: payload,
          cart: state.lineItems
        },
        { root: true }
      )
    }
    // console.warn(lineItem, state.lineItems)
    return lineItem
  },
  async removeLineItem({ state, rootState, dispatch, commit }, payload) {
    if (rootState.events) {
      const index = getLineItemIndex(state.lineItems, payload)
      const lineItem = state.lineItems[index]
      if (lineItem) {
        dispatch(
          'events/removeFromCart',
          {
            product: lineItem,
            cart: state.lineItems
          },
          { root: true }
        )
      }
    }
    commit('removeLineItemMutation', payload)
    dispatch('saveLineItems', state.lineItems)
  },
  async incrementLineItem({ state, commit, dispatch }, payload) {
    commit('incrementLineItemMutation', payload)
    dispatch('saveLineItems', state.lineItems)
  },
  async decrementLineItem({ state, commit, dispatch }, payload) {
    commit('decrementLineItemMutation', payload)
    dispatch('saveLineItems', state.lineItems)
  },
  /**
   * lineItem = { ...product, variant, customAttributes }
   * @param {object} context
   * @param {object} payload
   * @param {object} payload.item
   * @param {object[]} [payload.props]
   */
  async addLineItemWithProps({ dispatch, commit }, { item, props }) {
    try {
      commit('addLineItemWithProps', { item, props })
      await dispatch('saveLineItems', state.lineItems)
    } catch (err) {
      console.error('unable to add line item with props', err)
      throw err
    }
  },
  async addLineItemWithProp({ dispatch, commit }, { item, prop }) {
    try {
      commit('addLineItemWithProps', { item, props: [ prop ] })
      await dispatch('saveLineItems', state.lineItems)
    } catch (err) {
      console.error('unable to add line item with props', err)
      throw err
    }
  },
  /**
   * @param {object} context
   * @param {{item, props}[]} items
   *
   * ```
   * export default {
   *   template: `
   *     <div class="c-component">
   *       <my-component
   *         @add-line-items="addLineItemsWithProps"
   *       />
   *     </div>
   *   `,
   *   methods: { ...mapActions('cart', ['addLineItemsWithProps']) }
   * }
   * ```
   */
  async addLineItemsWithProps({ dispatch }, items) {
    try {
      for (let { item, props } of items) {
        await dispatch('addLineItemWithProps', { item, props })
      }
    } catch (err) {
      console.error(err)
    }
  },
  async saveLineItems({ state }) {
    await localforage.setItem('line-items', state.lineItems)
  },
  async resetLineItems({ commit }) {
    await localforage.removeItem('line-items')
    commit('setLineItems', [])
  },
  async initializeCart({ commit, state }) {
    const lineItems = await localforage.getItem('line-items')
    // Use value provided in content model if available
    const freeShippingThreshold = state.freeShippingThreshold || 100;
    /* Turn off Redo */
    /* const lastRedoOffTime = localStorage.getItem('redoOffTime');
    if (lastRedoOffTime && lastRedoOffTime > Date.now() - 24 * 60 * 60 * 1000) {
      commit('disableRedo')
    } */
    commit('setLineItems', lineItems || [])
    commit('setFreeShippingThreshold', freeShippingThreshold);
    commit('hideCart')
  },
  /**
   * this needs to be run during application boot for certain functions of the
   * cart to work.
   * ```
   * export default function ({ store }) {
   *   store.commit('cart/hideCart')
   *   const cartProfileHandle = (
   *     store.getters['space/getMetafield']('cart', * 'profile') ||
   *     'defaultProfile'
   *   )
   *   store.dispatch('cart/loadProfile', cartProfileHandle)
   *     .catch(err=> console.error(err))
   * }
   * ```
   */
  async loadProfile({ commit }, profileHandle) {
    try {
      const profile = await this.$api.getContentItem({
        handle: profileHandle,
        type: 'cartProfile',
      })
      //console.log('cart/loadProfile', profileHandle, profile)
      if (profile && profile.handle) {
        commit('setCartProfile', { ...profile, profileHandle })
      }
    } catch (err) {
      console.warn(err)
    }
  },
  addRedoToCart({ commit }) {
    commit('addRedoToCart')
  },
  removeRedoFromCart({ commit }) {
    commit('removeRedoFromCart')
  },
  enableRedo({ commit }) {
    commit('enableRedo')
  },
  disableRedo({ commit }) {
    commit('disableRedo')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
