import get from 'lodash/get'
import getProductVariantId from '~/utils/getProductVariantId'
// shopping gives
export const shoppingGivesPlugin = (store) => {
  const shoppingGivesHandler = async (mutation) => {
    if (mutation.type === 'events/addEvent') {
      const { eventType } = mutation.payload
      const lineItems = store.state.cart.lineItems
      switch (eventType) {
      case 'ADD_TO_CART':
        // cart has changed, reset cart items data
        window.cc_cart_items = []
        for (let i = 0; i < lineItems.length; i++) {
          const lineItem = lineItems[i]
          const variantId = get(lineItem, 'variant.id')
          const ccData = {
            id: getProductVariantId(variantId),
            price: get(lineItem, 'variant.price'),
            quantity: get(lineItem, 'quantity'),
            discount: get(lineItem, 'compareAtPrice')
          }
          window.cc_cart_items.push(ccData)
        }
        if (window.sgCartControllers) {
          for (let x = 0; x < window.sgCartControllers.length; x++) {
            window.sgCartControllers[x].refreshCart()
          }
        }
      }
    }
  }
  if (process.client) {
    if (!window.cc_cart_items) {
      window.cc_cart_items = []
    }
    store.subscribe(shoppingGivesHandler)
  }
}
