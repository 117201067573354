import { set } from "core-js/library/fn/dict"

export const state = () => ({
  modalOpen: false,
  modalContext: null,
  modalData: null,
  dynamicBundleVisible: false
})

export const mutations = {
  openModal(state) {
    state.modalOpen = true
  },

  closeModal(state) {
    state.modalOpen = false
  },

  setModalContext(state, payload) {
    state.modalContext = payload
  },

  setModalData(state, payload) {
    state.modalData = payload
  },
  setDynamicBundleVisible(state, payload) {
    state.dynamicBundleVisible = payload
  }
}

export const actions = {
  async toggleModal({ state, commit }) {
    const { modalOpen } = state
    if (modalOpen) {
      commit('closeModal')
    } else {
      commit('openModal')
    }
  },
  dynamicBundleVisible({commit}, payload) {
    commit('setDynamicBundleVisible',payload)
  }
}

export default {
  namespaced: true,
  actions,
  state,
  mutations
}
