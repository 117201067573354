import get from 'lodash/get'
import { mapProduct } from '@/utils/shopifyHelper'
import { engravingKeys, isLineItem } from '~/utils/cartHelper'
export const state = () => ({
  product: null,
  variant: null,
  isBundle: false,
  allowedLocations: [],
  mode: null,
  open: false,
  upsell: false,
  engravingKeys: [ ...engravingKeys ],
  ringsToEngrave: [],
  selectedItem: null
})
export const mutations = {
  setProduct(state, product) {
    state.product = product
  },
  setVariant(state, variant) {
    state.variant = variant
  },
  setisBundle(state, isBundle) {
    state.isBundle = isBundle
  },
  setAllowedLocations(state, locations) {
    state.allowedLocations = locations
  },
  setOpen(state, payload) {
    state.open = !!payload
  },
  setMode(state, value) {
    state.mode = value
  },
  resetContext(state) {
    state.product = null
    state.variant = null
    state.isBundle = false
    state.allowedLocations = []
    state.mode = null
    state.open = false
    state.engravingKeys = [ ...engravingKeys ]
    state.upsell = false
    state.selectedItem = null
  },
  setUpsell(state, upsell) {
    state.upsell = upsell;
  },
  addRingToEngrave(state, ring) {
    state.ringsToEngrave = [ring];
  },
  addRingsToEngrave(state,rings) {
    state.ringsToEngrave = rings;
  },
  setSelectedItem(state, item) {
    state.selectedItem = item
  }
}
export const getters = {
  engravingVisible({ open }, { context }) {
    const context0 = context && context.length && context[0]
    return open && !!(context0 && context0.product && context0.variant)
  },
  // FIXME use a general term here, rings are not the only things that can be
  // engraved using this module.
  context({ ringsToEngrave, open, upsell, selectedItem }) {
    let arrayOfRings = []
    // FIXME prefer for...of for this kind of iteration
    for (let i  = 0; i < ringsToEngrave.length; i++) {
      let { product, variant, allowedLocations, mode, isBundle } = ringsToEngrave[i]
      let context = {
        mode,
        open,
        upsell,
        product: (product && !product.metafieldsMap)
          ? mapProduct(product)
          : product,
        variant,
        allowedLocations,
        isBundle,
        selectedItem
      }

      switch (mode) {
      case 'mod':
        context.addEngravingLabel = 'Add Engraving'
        break
      }

      //Mod can only have engraving on the clasp or the sleeve not both. If clasp is present then on the clasp.
      if (
        context.product &&
        context.product.metafieldsMap &&
        mode !== 'mod'
      ) {
        try {
          const engraving = get(context, 'product.metafieldsMap.engraving.value')
          if (product && product.customAttributes && product.customAttributes.find((obj) => obj.key == "clasp")) {
            if (product.customAttributes.find((obj) => obj.key == "sleeve")) {
              context.allowedLocations = ["sleeve"]
            } else if (!product.customAttributes.find((obj) => obj.key == "sleeve")) {
              context.allowedLocations = ["band"]
            }
          } else {
            context.allowedLocations = engraving.location
          }
        } catch (err) {
          console.error(
            'invalid context, product is missing engraving metafields! (FYI this error will throw in production)',
            { handle: context.product.handle, metafields: context.product.metafieldsMap },
            err
          )
          if (process.env.NODE_ENV === 'production') {
            throw new Error('InvalidEngravingContext', { cause: err })
          }
        }
      }
      if (product && product.customAttributes) {
        for (let { key, value } of product.customAttributes) {
          switch (key) {
          case 'text':
          case 'location':
          case 'font':
            context[key] = value
            break
          }
        }
        context.addEngravingLabel = 'Save Changes'
      }
      // return context
      arrayOfRings.push(context)
    }
    return arrayOfRings;
  }

}
export const actions = {
  async open({ commit }) {
    commit('setOpen', true)
  },
  async close({ commit }) {
    commit('setOpen', false)
  },
  async setContext({ commit }, { product, variant, allowedLocations, mode, open, isBundle, selectedItem }) {
    try {
      // FIXME usage of "addRing(s)ToEngrave seems to replace the use of the
      // product and variant context fields
      commit('setProduct', product)
      commit('setVariant', variant)
      commit('setAllowedLocations', allowedLocations)
      commit('setMode', mode)
      commit('setUpsell', false)
      commit('setisBundle', isBundle)
      commit('addRingToEngrave', { product, variant, allowedLocations, mode, isBundle })
      commit('setSelectedItem', selectedItem)
      if (open) commit('setOpen', true)
      else commit('setOpen', false)
    } catch (err) {
      console.error('unable to set context', err)
    }
  },
  async setRings({ commit },ringArray) {
    try {
      commit('addRingsToEngrave', ringArray);
      commit('setProduct', ringArray[0].product)
      commit('setVariant', ringArray[0].variant)
      commit('setUpsell', true)
      commit('setOpen', true)
      commit('setisBundle', ringArray[0].isBundle)
      commit('setSelectedItem', ringArray[0].selectedItem)
    } catch (err) {
      console.error('Unable to set rings')
    }
  },
  /**
   * @param {object} context
   * @param {object} payload
   * @param {object} payload.item
   * @param {object[]} payload.props
   *
   * payload.item can be *either* a line item OR a shopify product
   */
  async addEngraving({ commit, dispatch }, { item, props }) {
    if (isLineItem(item)) {
      // we are dealing with something that is already in the cart
      commit(
        'cart/setLineItemProperty',
        { id: item.id, props },
        { root: true }
      )
    } else {
      // we are dealing with a shopify product object, which isn't in the cart
      // yet
      await dispatch('cart/addLineItemWithProps', {
        item, props
      }, { root: true })
    }
    if (!item.moreThanOneEngraving || item.lastRing) {
      // closeEngraving
      await dispatch('close')
      // showCart
      commit('cart/showCart', null, { root: true })
      // reset context
      setTimeout(() => {
        commit('resetContext')
      }, 500)
    }
  },
  async deleteEngraving({ state, commit }, item) {
    const { engravingKeys } = state
    // remove engraving line item properties
    commit('cart/removeLineItemProperties', {
      id: item.id,
      keys: [ ...engravingKeys ]
    }, { root: true })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
