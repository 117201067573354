import axios from 'axios'
const axiosClient = axios.create()
/**
 * send a graphql request
 * @param {string} url
 * @param {string} query
 * @param {object} variables
 *
 * NOTE: variables are interpolated on the server. make sure you send both the
 * query and variables properties! it is tempting to use js template
 * interpolation, but this can cause problems in some situations that can be
 * hard to debug.
 *
 * ```
 * await graphql(url, `
 *   query GetProduct($handle: String!) {
 *     product(handle: $handle) {
 *       metafields,
 *       variants
 *     }
 *   }
 * `, { handle: 'collection-variant-label' })
 * ```
 *
 * @link https://graphql.org/learn/
 * @link https://stackoverflow.com/questions/52816623/
 */
export const graphql = ({
  url, query, variables, headers
}) => axiosClient({
  url,
  method: 'POST',
  data: JSON.stringify({ query, variables }),
  headers: {
    ...headers || {},
    'Content-Type': 'application/json'
  }
})
/**
 * prepare a graphql statement for later use. handy if you are making the same
 * request with different data. returns a function that you call with your
 * graphql variables to dispatch the request.
 *
 * copying the request exampe from above:
 *
 * ```
 * await graphql(url, `
 *   query GetProduct($handle: String!) {
 *     product(handle: $handle) {
 *       metafields,
 *       variants
 *     }
 *   }
 * `, { handle: 'collection-variant-label' })
 * ```
 *
 * to create an equivalent function call with createQuery we just separate the
 * query creation from the dispatch step:
 *
 * ```
 * const getProduct = createQuery(url, `
 *   query GetProduct($handle: String!) {
 *     product(handle: $handle) {
 *       metafields,
 *       variants
 *     }
 *   }
 * `)
 * await getProduct({ handle: 'collection-variant-label' })
 * ```
 */
export const createQuery = ({
  url, query, headers
}) => (variables) => graphql({
  url, query, variables, headers
})
