import get from 'lodash/get'
import isArray from 'lodash/isArray'
import { getPageType } from '~/utils/urlHelper'
import { getUserData } from '~/utils/accountHelper'
import { getParsedVariantId, getParsedId } from '@/utils/parseIds'
/**
 * read collection_label from product / line item
 */
const getCategory = (o) => {
  let result = get(o, 'metafieldsMap.collection_label.value') || null
  if ((result === null) && o && isArray(o.metafields)) {
    const metafield = o.metafields.find((v) => v && v.key && (v.key === 'collection_label'))
    if (metafield && metafield.value) {
      result = metafield.value
    }
  }
  return result
}
/**
 * gtm events plugin
 *
 * subscribes to the events module and pushes the appropriate data to the gtm
 * dataLayer object. see store/events.js for a list of supported events.
 *
 * this integration assumes we are using BVA's dataLayer setup. see also
 * `EnsoRings/ShopifyCheckout/src/liquid/snippets/gtm-data-layer.liquid` for
 * details.
 */
export default (store) => {
  /**
   * subscribed below
   */
  const eventsHandler = (mutation) => {
    if (mutation.type === 'events/addEvent') {
      const userData = getUserData()
      let logState = null
      let baseEvent = {
        pageType: null,
        event: null,
      }
      const { eventType, payload } = mutation.payload
      //console.log(`[gtm] events/${eventType}`, payload)
      switch (eventType) {
      case 'PAGE_VIEW':
        baseEvent.pageType = payload.pageType
        baseEvent.event = 'page_view'
        logState = {
          isCustomer: !!userData.userId,
          logState: null,
          customerType: null,
          userId: userData.userId || null,
          customerEmail: userData.customerEmail || null,
          customerName: userData.customerName || null,
          checkoutEmail: null,
          currency: 'USD',
          customerFirst: null,
          customerLast: null,
          totalOrders: null,
          totalSpent: null,
          event: 'Customer Details',
        }
        break
      case 'PRODUCT_VIEW':
        baseEvent.pageType = baseEvent.event = 'product'
        baseEvent.products = get(payload, 'product.variants', []).map((variant) => ({
          id: getParsedId(payload.product),
          sku: `${get(variant, 'sku')}`,
          variantId: getParsedVariantId(variant),
          productType: get(payload, 'product.productType'),
          name: `${get(payload, 'product.title')} (${get(variant, 'title')})`,
          price: `${get(variant, 'price')}`,
          currency: 'USD',
          category: getCategory(get(payload, 'product'))
        }))
        break
      case 'PRODUCT_SELECT':
        baseEvent.pageType = baseEvent.event = 'product'
        baseEvent.products = [{
          id: getParsedId(payload.product),
          sku: `${get(payload, 'variant.sku')}`,
          variantId: getParsedVariantId(payload.variant),
          productType: get(payload, 'product.productType'),
          name: get(payload, 'product.title'),
          price: `${get(payload, 'variant.price')}`,
          currency: 'USD',
          category: getCategory(get(payload, 'product'))
        }]
        break
      case 'TOGGLE_CART':
      case 'ADD_TO_CART':
        if (
          payload.cart && payload.cart.length &&
          ((payload.cartVisible === true) || (payload.cartVisible === undefined))
        ) {
          baseEvent.pageType = getPageType({ routeName: store.state.route.name })
          baseEvent.event = 'cart'
          baseEvent.products = payload.cart.map((lineItem) => ({
            id: getParsedId(lineItem),
            sku: `${get(lineItem, 'variant.sku')}`,
            variantId: getParsedVariantId(lineItem.variant),
            name: get(lineItem, 'title'),
            price: `${get(lineItem, 'variant.price')}`,
            comparePrice: `${get(lineItem, 'variant.compareAtPrice')}` || null,
            quantity: lineItem.quantity,
            category: getCategory(lineItem)
          }))
        }
        break
      }
      if (logState) {
        window.dataLayer.push(logState)
      }
      if (baseEvent.event) {
        window.dataLayer.push(baseEvent)
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('[gtm] dataLayer', window.dataLayer)
      }
    }
  }
  if (!process.server) {
    window.dataLayer = window.dataLayer || []
    store.subscribe((mutation, payload) => {
      try {
        eventsHandler(mutation, payload)
      } catch (err) {
        console.error('[gtm-events-plugin]', err)
      }
    })
  }
}
