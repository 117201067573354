import { getProduct } from './product'

export const getProducts = async ({
  handles,
  $nacelle,
  locale,
}) => {
  try {
    // console.log('getProducts', handles)
    // fetch the product list concurrently
    const products = await Promise.all(
      handles.map((handle) => getProduct({ handle, $nacelle, locale }))
    )
    let result = []
    for (let product of products) {
      if (product) {
        result.push(product)
      }
    }
    return result
  } catch (err) {
    if (
      ['test', 'development'].includes(process.env.NODE_ENV)
    ) {
      console.error('getProducts', err, err.cause)
    }
    throw err
  }
}

