import gtmEventsPlugin from '~/store-plugins/gtmEventsPlugin'
import { cartManagerPlugin } from '~/store-plugins/cartManager'
import { shoppingGivesPlugin } from '~/store-plugins/shoppingGives'
import sentryPlugin from '~/store-plugins/sentry'
import tiktokEventsPlugin from "~/store-plugins/tiktokEventsPlugin";

export const mutations = {}

export const state = () => ({
  collectionLimit: 12,
})

export const actions = {
  async nuxtServerInit(ctx, context) {
    await this.$nacelle.nacelleNuxtServerInit(ctx, context)
  }
}

export const plugins = [
  sentryPlugin,
  cartManagerPlugin,
  gtmEventsPlugin,
  shoppingGivesPlugin,
  tiktokEventsPlugin,
]
