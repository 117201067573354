import vercel from '../vercel.json'
import { mapRedirects } from '~/utils/mapRoutes'
/**
 * @param {object} ctx
 * @param {object} ctx.app
 * @param {object} ctx.app.router
 */
export default function ({ app }) {
  const redirects = mapRedirects(vercel.redirects)
  app.router.beforeEach((to, from, next) => {
    // console.log('FIRST', to, from)
    let redirectMatch = false
    for (let [ matchFn, target ] of redirects) {
      const result = matchFn(to.fullPath)
      if (result) {
        // console.log('FIRST MATCH', to.fullPath, result, target)
        redirectMatch = true
        window.location = target(result)
        break
      }
    }
    // this will flash the 404 page in on first load if there isn't a server
    // side redirect in place. internal navigation doesn't have this problem
    // because this hook runs before anything on the page changes.
    if (!redirectMatch) {
      next()
    } else {
      next(false)
    }
  })
}

