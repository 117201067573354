import map from 'lodash/fp/map'
import { match } from 'path-to-regexp'
/**
 * @param {string} target
 * @link https://github.com/pillarjs/path-to-regexp#match
 */
export const createMatchFn = (path) => match(path, { decode: decodeURIComponent })
/**
 * @param {object} match
 * @param {object} match.params
 * @param {string} match.path
 * even though we get a rendered path here, it isn't useful to use because
 * some redirects are external.
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace
 */
export const formatRoute = ({ target, match: { params } }) => {
  let result = target
  for (let [key, value] of Object.entries(params)) {
    result = result.replace(`:${key}`, value)
  }
  // console.log('FIRST TARGET', result)
  return result
}
/**
 * @param {{source: string, destination: string}[]} vercelRoutes - vercelJson.routes
 * @return {{fnMatch: Function, target: string}[]}
 */
export const mapRedirects = map(({ source: path, destination: target }) => [
  createMatchFn(path),
  (match) => formatRoute({ target, match })
])
