/**
 * atob that works on the server and client side
 */
const fromBase64 = (v) => (process.client)
  ? window.atob(v)
  : Buffer.from(v, 'base64').toString('utf-8')
/**
 * convert a value to base64 (used to encode Basic Auth header)
 * @param {*} data
 * @return {string}
 */
const toBase64 = (data) => (process.client)
  ? window.btoa(data)
  : Buffer.from(data).toString('base64')

module.exports = { toBase64, fromBase64 }
