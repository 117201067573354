import uniqueId from 'lodash/uniqueId'

/**
 * @param {string} key - the data property where this content item should be
 * stored
 * @param {object} options
 * @param {string} options.handle
 * @param {string} options.type
 */
export default (key, { handle, type, mapping }) => {
  const fetchMethodName = uniqueId(`getContentItem${key}${handle}${type}Fetch`)
  return {
    methods: {
      async [fetchMethodName]() {
        if (this[key] === null) {
          //console.log('getContentItem', handle, mapping)
          try {
            this[key] = await this.$api.getContentItem({
              handle,
              type,
              mapping,
            })
          } catch (err) {
            if (['test', 'development'].includes(process.env.NODE_ENV)) {
              console.warn('get-content-item', err)
            }
          }
        }
      }
    },
    async fetch() {
      await this[fetchMethodName]()
    },
    mounted() {
      if (process.client) {
        this[fetchMethodName]()
      }
    },
    data() {
      return {
        [key]: null
      }
    }
  }
}
