/**
 * shopify provider for nuxt-image
 * @link https://v0.image.nuxtjs.org/advanced/custom-provider
 */
export function getImage(src, { modifiers, baseURL } = {}, { options, nuxtContext, $img }) {
  // parse url and search params so we can modify them based on the given
  // modifiers
  let url = new URL(src)
  let params = new URLSearchParams(url.search)
  // only apply transforms that are supported by the upstream
  for (let [ type, val ] of Object.entries(modifiers)) {
    if ((type === 'width') && val && (val !== 'undefined')) {
      params.set(type, val)
    }
  }
  url.search = `?${params.toString()}`
  //console.log('[shopify provider]', params.toString(), url.host, url.protocol, url.pathname, url.toString())
  return {
    url: url.toString()
  }
}
