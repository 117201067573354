import get from 'lodash/get'
import isNaN from 'lodash/isNaN'
import isNumber from 'lodash/isNumber'
import { getBundleMetafields, formatPackingSlipProp } from '@/utils/bundleHelper'
import { mapProduct } from '@/utils/shopifyHelper'
export const state = () => ({
  bracelets: null,
  clasps: null,
  sleeves: null,
  sleeveLaborSku: null,
  claspLaborSku: null,
  fees: null,
  selections: {
    bracelet: null,
    clasp: null,
    sleeve: null,
    size: null
  },
  steps: null,
  currentStep: null,
  lineItemProps: null,
  feeHandles: null,
  starWarsType: null,
  queryStringOnce: true
})

export const mutations = {
  reset(state) {
    state.bracelets = null
    state.clasps = null
    state.sleeves = null
    state.sleeveLaborSku = null
    state.claspLaborSku = null
    state.fees = null
    state.selections = {
      bracelet: null,
      clasp: null,
      sleeve: null,
      size: null
    }
    state.steps = null
    state.currentStep = null
    state.lineItemProps = null
  },
  update({ selections }, options) {
    for (const key of Object.keys(selections)) {
      if (typeof options[key] !== 'undefined') {
        selections[key] = options[key]
      }
    }
  },
  setLineItemProps(state, payload) {
    state.lineItemProps = payload
  },
  setFeeHandles(state, payload) {
    state.feeHandles = payload
  },
  setBracelets(state, payload) {
    //add top level product metafields to all variants objects - allows product color mixin to work correctly.
    payload.forEach(function (band) {
      let meta = band.metafields
      band.variants.forEach(function (variant) {
        variant.metafields = meta
      })
      //add top level bracelet product handle to each variant
      band.variants = band.variants.map(function (variant) {
        variant.handle = band.handle
        return variant
      })
    })
    state.bracelets = payload
  },
  setClasps(state, payload) {
    payload.forEach(function (clasp) {
      let meta = clasp.metafields
      clasp.variants.forEach(function (variant) {
        variant.metafields = meta
      })
    })

    state.clasps = payload
  },
  setSleeves(state, payload) {
    state.sleeves = payload
  },
  setSleeveLaborSku(state, payload) {
    state.sleeveLaborSku = payload
  },
  setClaspLaborSku(state, payload) {
    state.claspLaborSku = payload
  },
  setFees(state, payload) {
    state.fees = payload
  },
  setSteps(state, payload) {
    state.steps = payload
  },
  setStep(state, payload) {
    state.currentStep = payload
  },
  setStarWarsType(state, payload) {
    state.starWarsType = payload
  },
  setQueryStringOnce(state, payload) {
    state.queryStringOnce = payload
  }
}

export const actions = {
  resetBuilder({ commit }) {
    commit('reset')
  },
  async loadPage({ commit }) {
    const page = await this.$api.getPage({
      handle: 'mod-builder',
    })
    const feeHandles = get(page, 'sections[0].fields.feeHandles')
    commit('setFeeHandles', feeHandles)
  },
  loadSteps({ commit }, payload) {
    commit('setSteps', payload)
    //load first step
    commit('setStep', payload[0])
  },
  incrementStep({ commit, state, getters }) {
    if (typeof state.steps[getters.currentStepIndex + 1] !== 'undefined') {
      commit('setStep', state.steps[getters.currentStepIndex + 1])
    }
  },
  decrementStep({ commit, state, getters }) {
    if (typeof state.steps[getters.currentStepIndex - 1] !== 'undefined') {
      commit('setStep', state.steps[getters.currentStepIndex - 1])
    }
  },
  jumpToStep({ commit, state }, payload) {
    const nextStep = state.steps.find(step => {
      return step.shortTitle.toLowerCase() === payload.toLowerCase()
    })
    if (nextStep) {
      commit('setStep', nextStep)
    }
  },
  toggleSleeve({ commit, state, getters }) {
    if (!state.selections.sleeve) {
      return commit('update', { sleeve: getters.compatibleSleeve })
    }
    //unset sleeve
    return commit('update', { sleeve: false })
  },
  async addEngraving({ state, dispatch }) {
    const lineItemProps = state.lineItemProps
    const bracelets = state.bracelets
    const { bracelet, sleeve } = state.selections
    const allowedLocations = (sleeve) ? [ 'sleeve' ] : [ 'band' ]
    let product = null
    for (let item of (bracelets || [])) {
      if (item.handle === bracelet.handle) {
        product = item
        break
      }
    }
    if (product) {
      try {
        await dispatch('engraving/setContext', {
          mode: 'mod',
          allowedLocations,
          variant: bracelet,
          product: {
            ...product,
            quantity: 1,
            customAttributes: [ ...(lineItemProps || []) ]
          }
        }, { root: true })
        await dispatch('engraving/open', null, { root: true })
      } catch (err) {
        console.error(err)
      }
    } else {
      console.warn('unable to start engraving')
    }
  },
  async addToCart({ getters, dispatch }) {
    try {
      await dispatch(
        'cart/addLineItemsWithProps',
        getters.cartPayload,
        { root: true }
      )
    } catch (err) {
      console.error(err)
    }
  }
}

export const getters = {
  loading(state) {
    return (
      state.selections.bracelet === null ||
      state.selections.clasp === null ||
      state.steps === null
    )
  },
  currentStepIndex(state) {
    if (state.currentStep) {
      return state.steps.indexOf(state.currentStep)
    }
    return null
  },
  prevStep(state, getters) {
    if (
      state.steps &&
      (typeof state.steps[getters.currentStepIndex - 1] !== 'undefined')
    ) {
      return state.steps[getters.currentStepIndex - 1]
    }
    return null
  },
  nextStep(state, getters) {
    if (
      state.steps &&
      (typeof state.steps[getters.currentStepIndex + 1] !== 'undefined')
    ) {
      return state.steps[getters.currentStepIndex + 1]
    }
    return null
  },
  engravingFee(state) {
    let engravingFee = state.fees.find(function (fee) {
      return fee.handle === 'engraving-fee'
    })
    return engravingFee ? engravingFee.variants[0] : null
  },
  sleeveFee(state) {
    let sleeveFee = state.fees.find(function (fee) {
      return fee.handle === 'bracelet-sleeve-fee'
    })
    return sleeveFee ? sleeveFee.variants[0] : null
  },
  sleevelaborFee(state) {
    let laborFee = state.fees.find(function (fee) {
      return fee.handle === 'labor-charge'
    })
    return laborFee ? laborFee.variants.find(function (variant) {
      return variant.sku === state.sleeveLaborSku 
    }) : null
  },
  clasplaborFee(state) {
    let laborFee = state.fees.find(function (fee) {
      return fee.handle === 'labor-charge'
    })
    return laborFee ? laborFee.variants.find(function (variant) {
      return variant.sku === state.claspLaborSku 
    }) : null
  },
  bandColor(state) {
    if (state.selections.bracelet) {
      let color = state.selections.bracelet.metafields.find(function (meta) {
        return meta.key === 'color'
      })
      if (color)
        return JSON.parse(color.value).name
    }
    return null
  },
  claspColor(state) {
    if (state.selections.clasp) {
      let color = state.selections.clasp.metafields.find(function (meta) {
        return meta.key === 'color'
      })
      if (color)
        return JSON.parse(color.value).name
    }
    return null
  },
  claspFinish(state) {
    if (state.selections.clasp) {
      return state.selections.clasp.title
    }
    return null
  },
  compatibleSleeve(state, getters) {
    if (state.sleeves) {
      let sleeve = state.sleeves.find(function (sleeve) {
        return sleeve.metafields.find(function (meta) {
          return (
            meta.key === 'color' &&
            JSON.parse(meta.value).name === getters.claspColor
          )
        })
      })
      if (sleeve) {
        sleeve = sleeve.variants.find(function (variant) {
          return variant.title === getters.claspFinish
        })
        return sleeve
      }

    }
    return null
  },
  appliedFees({ selections, fees, lineItemProps }) {
    let result = []
    for (let fee of fees) {
      switch (fee.handle) {
      case 'bracelet-sleeve-fee':
        if (selections.sleeve) {
          result.push(fee)
        }
        break
      case 'engraving-fee':
        if (lineItemProps && (lineItemProps.length > 0)) {
          result.push(fee)
        }
        break
      }
    }
    return result;
  },
  subtotal({ selections }, getters) {
    let result = 0.0
    for (let selection of Object.values(selections)) {
      if (selection) {
        const price = isNumber(selection.price)
          ? selection.price
          : parseFloat(selection.price)
        result += isNaN(price) ? 0.0 : price
      }
    }
    for (let fee of getters.appliedFees) {
      const variant = get(fee, 'variants[0]')
      if (variant && variant.price) {
        const price = isNumber(variant.price)
          ? variant.price
          : parseFloat(variant.price)
        result += isNaN(price) ? 0.0 : price
      }
    }
    return result
  },
  compareAtSubtotal({ selections }, getters) {
    let result = 0.0
    for (let selection of Object.values(selections)) {
      if (selection) {
        let productPrice = null
        if (selection.compareAtPrice) {
          productPrice = selection.compareAtPrice
        } else {
          productPrice = selection.price
        }
        const price = isNumber(productPrice)
          ? productPrice
          : parseFloat(productPrice)
        result += isNaN(price) ? 0.0 : price
      }
    }
    for (let fee of getters.appliedFees) {
      const variant = get(fee, 'variants[0]')
      if (variant && variant.compareAtPrice) {
        const price = isNumber(variant.compareAtPrice)
          ? variant.compareAtPrice
          : parseFloat(variant.compareAtPrice)
        result += isNaN(price) ? 0.0 : price
      }
    }
    return result
  },
  cartPayload({
    bracelets,
    selections,
    //fees,
    lineItemProps
  }, getters) {
    let result = {}
    let configuratorData = {
      "type": "kit",
      "items": []
    }
    let packingSlipItemOptions = []
    for (let [ key, value ] of Object.entries(selections)) {
      // console.log(value)
      let bundleMetafields = getBundleMetafields({ item: { variant: value } })
      switch (key) {
      case 'bracelet':
        for (let bracelet of bracelets) {
          if (value && (bracelet.handle === value.handle)) {
            result[key] = {
              item: { ...bracelet, variant: value, quantity: 1 },
              props: (lineItemProps) ? [ ...lineItemProps ] : []
            }
            bundleMetafields = getBundleMetafields({
              item: mapProduct({ ...bracelet, variant: value })
            })
            // console.log('BOM MOD', { bracelet, variant: value, lineItemProps })
            packingSlipItemOptions.push(
              bundleMetafields.packingSlipItemOption
              /*
              bundleMetafields.renderItemOption({
                partId: undefined,
                quantity: 1,
              })
              */
            )
          }
        }
        break
      case 'clasp':
        // console.log(value, briefDescription, getters.claspColor, getters.claspFinish)
        result.bracelet.props.push({
          key,
          value: `${value.sku ? value.sku : ''} ${getters.claspColor} ${getters.claspFinish}`
        })
        //removing clasp labor fee as the netsuite kit contains that.
        // if (getters.clasplaborFee) {
        //   configuratorData.items.push({
        //     "quantity": 1,
        //     "part_id": getters.clasplaborFee.sku
        //   })
        // }
        configuratorData.items.push({
          "quantity": 1,
          "part_id": value.sku
        })
        packingSlipItemOptions.push(
          bundleMetafields.packingSlipItemOption
        )
        break
      case 'sleeve':
        if (selections.sleeve) {
          result.bracelet.props.push({
            key,
            value: `${value.sku} ${getters.claspColor} ${getters.claspFinish}`
          })
          if (getters.sleevelaborFee) {
            configuratorData.items.push({
              "quantity": 1,
              "part_id": getters.sleevelaborFee.sku
            })
          }
          configuratorData.items.push({
            "quantity": 1,
            "part_id": value.sku
          })
          packingSlipItemOptions.push(
            bundleMetafields.packingSlipItemOption
          )
        }
        break
      case 'size':
        result.bracelet.props.push({ key: 'size', value })
        break
      }
    }
    // fees don't need to be passed to the cart, they're managed by
    // ~/utils/cartManager from here on out
    if (process.env.CONFIGURATOR_DATA_ENABLED) {
      result.bracelet.props.push({
        key: "_configurator_data",
        value: configuratorData
      });
      if (packingSlipItemOptions && packingSlipItemOptions.length) {
        result.bracelet.props.push(
          formatPackingSlipProp({ packingSlipItemOptions })
        )
      }
    }
    return Object.values(result)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
