/**
 * easily crashes dev tools on chrome. for a better dev experience, try firefox.
 */
import { mapProduct } from '@/utils/shopifyHelper'
import { isHiddenProduct } from '~/utils/nuxt'
import uniq from 'lodash/uniq'

export const state = () => ({
  query: undefined,
  filtersCleared: false,
  searchData: {},
  filteredData: null,
  loadedData: false,
  searchLoading: false,
  resultsToDisplay: 12,
  searchVisible: false,
  autocompleteVisible: false,
  autoCompleteData: [],
})

export const getters = {
  queryOrigin(state) {
    if (state.query && state.query.origin) {
      return state.query.origin
    }

    return undefined
  },

  hasProductData(state) {
    return (
      state.searchData &&
      state.searchData.products &&
      state.searchData.products.length > 0
    )
  },
  productData(state) {
    if (
      state.searchData &&
      state.searchData.products &&
      state.searchData.products.length > 0
    ) {
      return state.searchData.products
    }
    return []
  }
}

export const mutations = {
  setQuery(state, query) {
    state.query = query
  },

  setFilteredData(state, data) {
    state.filteredData = data
  },

  setAutocompleteVisible(state) {
    state.autocompleteVisible = true
  },

  setAutocompleteNotVisible(state) {
    state.autocompleteVisible = false
  },

  setAutoCompleteData(state, data) {
    state.autoCompleteData = data
  },

  showMoreResults(state) {
    state.resultsToDisplay = state.resultsToDisplay + 12
  },

  resetResults(state) {
    state.resultsToDisplay = 12
  },

  setFiltersCleared(state) {
    state.filtersCleared = true
  },

  setFiltersNotCleared(state) {
    state.filtersCleared = false
  },

  setSearchData(state, data) {
    state.searchData = {
      ...state.searchData,
      ...data
    }
  },

  dataHasLoaded(state) {
    state.loadedData = true
  },

  dataNotLoaded(state) {
    state.loadedData = false
  },

  isSearching(state) {
    state.searchLoading = true
  },

  isNotSearching(state) {
    state.searchLoading = false
  },
  showSearch(state) {
    state.searchVisible = true
  },
  hideSearch(state) {
    state.searchVisible = false
  },
  toggleSearch(state) {
    state.searchVisible = !state.searchVisible
  },
}

// this list only excludes products from search, to exclude the route AND search
// use the hiddenProducts option in nuxt.config.js
const hiddenProducts = [
  'bracelet-sleeve-fee',
  'domestic-shipping-charge',
  'dynamic-bundle-cello-bag',
  'engraving-fee',
  'influencer-exclusive-order',
  'inked-exclusive-collection',
  'international-shipping-charge',
  'product-upcharge',
  'rounded-metal-bracelet-clasp-black',
  'rounded-metal-bracelet-clasp-rose-gold',
  'rounded-metal-bracelet-clasp-silver',
  'rounded-metal-bracelet-sleeve-black',
  'rounded-metal-bracelet-sleeve-rose-gold',
  'rounded-metal-bracelet-sleeve-silver',
  'routeins',
  'upcharge',
]
export const actions = {
  async getProductData({ commit, getters }) {
    if (!getters.hasProductData) {
      commit('dataNotLoaded')
      commit('isSearching')
      let res = null
      try {
        res = await this.$axios.get('/data/search.json')
      } catch (err) {
        console.error(err)
      }
      if (res && res.data) {
        commit('isNotSearching')
        let result = []
        for (let product of res.data.product) {
          if (
            hiddenProducts.includes(product.handle) ||
            isHiddenProduct(product, this.$config)
          ) {
            continue
          } else {
            try {
              result.push(product)
            } catch (err) {
              console.error(err)
            }
          }
        }
        //console.log('getProductData', { res, config: this.$config, result })
        commit('setSearchData', { products: result })
        commit('dataHasLoaded')
      }
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
