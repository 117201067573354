// this file is used in a serverless function, so we can't use aliases here
import { getProducts } from './products'
import { dataWrapper } from './utils'
import { sortProducts } from '@enso-rings/component-library/src/utils/collectionHelper'
import get from 'lodash/get'

/**
 * @param {object} options
 * @param {object} options.collection
 * @param {string} options.locale
 * @param {string} options.selectedList
 * @param {number} [options.productIndex]
 * @param {number} [options.itemsPerPage]
 * @param {object} $nacelle
 * @param {object} sortOptions
 *
 * ## pagination
 * defaults to `slice(0, undefined)`, which gives all products
 * slice(0, 0 + 30) → first 30 products
 * slice(30, 30 + 30) → next 30 products
 */
const getCollectionProducts = async (
  { collection, locale, selectedList, productIndex, itemsPerPage },
  $nacelle,
  sortOptions
) => {
  // fetch listed products
  // console.log('collection', collection)
  if (
    collection &&
    collection.productLists &&
    collection.productLists.length > 0
  ) {
    let productList = {}
    for (let list of collection.productLists) {
      if (list.slug === selectedList) {
        productList = list
        break
      }
    }
    // console.log('productList', productList)
    if (process.env.COLLECTION_SORT_API) {
      const products = await getProducts({
        handles: productList.handles,
        $nacelle,
        locale
      })
      return sortProducts(products, sortOptions).slice(
        productIndex,
        itemsPerPage ? itemsPerPage + productIndex : undefined
      )
    } else {
      const products = await getProducts({
        handles: productList.handles,
        $nacelle,
        locale
      })
      // console.log('collectionProducts', collection.handle, productList.handles.length, products.length)
      return products
    }
  } else {
    return []
  }
}

/**
 * refactored out of ~/mixins/getCollection to support more dynamic use cases
 *
 * @param {object} options - options object
 mic bundle* @param {object} options.$nacelle - nacelle sdk
 * @param {string} options.handle - collection handle
 * @param {number} options.itemsPerPage
 * @param {string} options.selectedList - list handle
 * @param {string} options.locale - current locale
 */
export const getCollection = async ({
  $axios,
  $nacelle,
  handle,
  itemsPerPage,
  index,
  locale,
  selectedList,
  sortOptions,
  omitProducts,
}) => {
  //console.log('get collection', typeof handle, handle)
  let result = {
    collection: null,
    collectionHandle: handle,
    itemsPerPage,
    locale: locale || get($nacelle, 'locale') || 'en-us',
    productIndex: index || 0,
    products: [],
    selectedList: selectedList || 'default'
  }
  if (handle && handle !== 'null' && handle !== 'undefined') {
    try {
      if (process.env.COLLECTION_SORT_API && $axios) {
        // use serverless function to apply sort using on-disk data
        let params = { locale: result.locale }
        if (result.itemsPerPage) {
          params.itemsPerPage = result.itemsPerPage
          params.index = result.productIndex
        }
        if (sortOptions && sortOptions.order) {
          params.order = JSON.stringify(sortOptions.order)
        }
        // nuxt injects the wrong origin if we don't give an absolute url
        const response = await $axios({
          method: 'GET',
          url: `${location.origin}/api/v1/collection/${handle}`,
          params
        })
        result.products = get(response, 'data.products') || []
        result.collection = get(response, 'data.collection') || null
      } else {
        result.collection = await dataWrapper($nacelle.data, 'collection', {
          handle,
          locale: result.locale
        })
        if (!omitProducts) {
          result.products = await getCollectionProducts(result, $nacelle)
        }
      }
    } catch (err) {
      result.noCollectionData = true
      if (
        ['test', 'development'].includes(process.env.NODE_ENV)
      ) {
        console.warn(`[dataLoaders/getCollection]`, handle, err)
      }
      throw err
    }
    return result
  } else {
    return result
  }
}

export const getCollections = async ({
  collections,
  $nacelle,
  sortOptions,
  $axios,
  omitProducts,
}) => {
  let result = []
  for (let collection of collections) {
    // each of these will load all products in a single Promise.all, so its not
    // necessarily helpful to also queue up all of the collection-related I/O at
    // the same time.
    result.push(
      await getCollection({
        handle: collection,
        $axios,
        $nacelle,
        sortOptions,
        omitProducts,
      })
    )
  }
  return result
}
