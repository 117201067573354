/**
 * @param {string[]} options - regex strings to match against
 * @return {RegExp[]}
 */
const compileAllowedHosts = (options) => options.map((option) => {
  return new RegExp(`^${option}$`, 'i')
})
/**
 * @param {string} hostname
 * @param {RegExp[]} allowedHosts
 */
const isAllowedHost = (hostname, allowedHosts) => {
  for (let hostRegex of allowedHosts) {
    if (hostRegex.test(hostname)) {
      return true
    }
  }
}
let allowedHosts = []
export default ({ route, $config }) => {
  if (
    process.client && (process.env.NODE_ENV === 'production')
  ) {
    const hostname = window.location.hostname
    if (allowedHosts.length === 0) {
      allowedHosts = compileAllowedHosts($config.ALLOWED_HOSTS)
    }
    if (hostname && !isAllowedHost(hostname, allowedHosts)) {
      const path = route.fullPath
      const query = window.location.search
      window.location = `https://ensorings.com${path}${query}`
    }
  }
}
