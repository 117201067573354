import camelCase from 'lodash/camelCase'
import clone from 'lodash/clone'
import get from 'lodash/get'
import pick from 'lodash/pick'
import uniqueId from 'lodash/uniqueId'
import toNumber from 'lodash/toNumber'
// @ts-ignore
import RichTextResolver from 'storyblok-js-client/dist/richTextResolver.umd.js'
const typeMap: { [index: string]: Function } = {
  stickerCard(blok: Blok & {
    title: string
    description: any
    sticker: any
  }) {
    const resolver = new RichTextResolver()
    return {
      contentType: blok.component,
      type: blok.component,
      title: blok.title,
      description: blok.description ? resolver.render(blok.description) : '',
      sticker: {
        alt: get(blok, 'sticker.alt'),
        src: get(blok, 'sticker.filename'),
        title: get(blok, 'sticker.title')
      }
    }
  },
  heroBanner(blok: Blok) {
    const type = get(blok, 'type')
    let heroData = {}
    if (type) {
      heroData = get(get(blok, type, []).map(mapStory), '0')
    }

    return {
      contentType: blok.component,
      data: {
        ...heroData,
        type,
      }
    }
  },
  hero(blok: Blok & {
    type: string
    Type: string
    cta: Array<Cta>
  }): {
    entryTitle: string
    type: string
    contentType: string | void
    title: string | void
    subtitle: string | void
    textHiddenMobile: boolean | void
    textHiddenDesktop: boolean | void
    textColor: string | void
    positionButtonToBottom: boolean | void
    mobileTextColor: string | void
    mobileBackgroundImage: MediaObject | void
    desktopBackgroundImage: MediaObject | void
    lifestyleAlignment: string | void
    cta: Cta | {}
    description: string | void
  } {
    return {
      // this is not good for carousels
      type: blok.component || blok.type || blok.Type,
      title: get(blok, 'title[0].Text'),
      subtitle: get(blok, 'subtitle[0].Text'),
      textHiddenMobile: get(blok, 'textHiddenMobile'),
      textHiddenDesktop: get(blok, 'textHiddenDesktop'),
      textColor: get(blok, 'title[0].Color'),
      positionButtonToBottom: get(blok, 'positionButtonToBottom'),
      mobileTextColor: get(blok, 'title[0].Color'),
      mobileBackgroundImage: {
        alt: get(blok, 'desktopBackgroundImage[0].mobile.alt'),
        src: get(blok, 'desktopBackgroundImage[0].mobile.filename'),
        title: get(blok, 'desktopBackgroundImage[0].mobile.name')
      },
      desktopBackgroundImage: {
        alt: get(blok, 'desktopBackgroundImage[0].desktop.alt'),
        src: get(blok, 'desktopBackgroundImage[0].desktop.filename'),
        title: get(blok, 'desktopBackgroundImage[0].desktop.name')
      },
      lifestyleAlignment: get(blok,'lifestyleAlignment'),
      entryTitle: get(blok, 'entryTitle'),
      cta: blok.cta.length != 0 ? {
        buttonSize: get(blok, 'cta[0].buttonSize'),
        text: get(blok, 'cta[0].text'),
        type: get(blok, 'cta[0].type'),
        url: get(blok, 'cta[0].url'),
        externalUrl: get(blok, 'cta[0].externalUrl'),
        isDisabled: get(blok, 'cta[0].isDisabled'),
        customColor: get(blok, 'cta[0].customColor'),
        marginBottom: get(blok, 'cta[0].marginBottom'),
      } : {},
      description: get(blok, 'description[0].Text'),
      // @ts-ignore
      short: get(blok,'short')
    }
  },
  collectionHero(blok: Blok) {
    return {
      title: get(blok, 'Title'),
      subtitle: get(blok, 'Subtitle'),
      description: get(blok, 'Description'),
      readMore: get(blok, 'ReadMore'),
      mobileBackgroundImage: {
        alt: get(blok, 'BackgroundImage[0].mobile.alt'),
        src: get(blok, 'BackgroundImage[0].mobile.filename'),
        title: get(blok, 'BackgroundImage[0].mobile.name')
      },
      desktopBackgroundImage: {
        alt: get(blok, 'BackgroundImage[0].desktop.alt'),
        src: get(blok, 'BackgroundImage[0].desktop.filename'),
        title: get(blok, 'BackgroundImage[0].desktop.name')
      },
    }
  },
  productPageHero(blok: Blok) {
    const resolver = new RichTextResolver()
    const description = get(blok, 'description[0].RichText')

    let result = {
      title: get(blok, 'Title[0].Text'),
      titleTypography: get(blok, 'Title[0].HeadingStyle'),
      titleMarginBottom: get(blok, 'Title[0].MarginBottom'),
      titleColor: get(blok, 'Title[0].Color'),
      subtitle: get(blok, 'Subtitle[0].Text'),
      subtitleTypography: get(blok, 'Subtitle[0].HeadingStyle'),
      subtitleMarginBottom: get(blok, 'Subtitle[0].MarginBottom'),
      subtitleColor: get(blok, 'Subtitle[0].Color'),
      descriptionTypography: get(blok, 'description[0].HeadingStyle'),
      descriptionMarginBottom: get(blok, 'description[0].MarginBottom'),
      descriptionColor: get(blok, 'description[0].Color'),
      description: description ?  resolver.render(description) : '',
      textSide: get(blok, 'TextSide'),
      mobileBackgroundImage: {
        alt: get(blok, 'BackgroundImage[0].mobile.alt'),
        src: get(blok, 'BackgroundImage[0].mobile.filename'),
        title: get(blok, 'BackgroundImage[0].mobile.name')
      },
      desktopBackgroundImage: {
        alt: get(blok, 'BackgroundImage[0].desktop.alt'),
        src: get(blok, 'BackgroundImage[0].desktop.filename'),
        title: get(blok, 'BackgroundImage[0].desktop.name')
      },
      transparentOverlayType: get(blok, 'TransparentOverlay[0].Type'),
      transparentOverlayColor: get(blok, 'TransparentOverlay[0].Color'),
      imageLocation: get(blok, 'imageLocation')
    }

    return result;
  },
  imageH1Hero(blok: Blok) {
    let cta = get(blok, 'CTA.0', {})

    let result = {
      title: get(blok, 'Title'),
      subtitle: get(blok, 'Subtitle'),
      description: get(blok, 'Description'),
      mobileBackgroundImage: {
        alt: get(blok, 'BackgroundImage[0].mobile.alt'),
        src: get(blok, 'BackgroundImage[0].mobile.filename'),
        title: get(blok, 'BackgroundImage[0].mobile.name')
      },
      desktopBackgroundImage: {
        alt: get(blok, 'BackgroundImage[0].desktop.alt'),
        src: get(blok, 'BackgroundImage[0].desktop.filename'),
        title: get(blok, 'BackgroundImage[0].desktop.name')
      },
      hideImageDesktop: get(blok, 'HideImageDesktop'),
      lifestyleAlignment: get(blok, 'LifestyleAlignment'),
      image: {
        alt: get(blok, 'Image.alt'),
        src: get(blok, 'Image.filename')
      }
    }

    if (Object.keys(cta).length > 0) {
      return {
        ...result,
        cta: {
          ...cta,
          url: get(cta, 'url')
        }
      }
    } else {
      return result
    }
  },
  shortVideo(blok: Blok) {
    return {
      title: get(blok, 'Title'),
      subtitle: get(blok, 'Subtitle'),
      description: get(blok, 'Description'),
      mobileBackgroundImage: {
        alt: get(blok, 'BackgroundImage[0].mobile.alt'),
        src: get(blok, 'BackgroundImage[0].mobile.filename'),
        title: get(blok, 'BackgroundImage[0].mobile.name')
      },
      desktopBackgroundImage: {
        alt: get(blok, 'BackgroundImage[0].desktop.alt'),
        src: get(blok, 'BackgroundImage[0].desktop.filename'),
        title: get(blok, 'BackgroundImage[0].desktop.name')
      }
    }
  },
  guidedQuiz(blok: Blok & {
    contentType: string
    handle: string
    Questions: Array<Blok>
    entryTitle: string
    title: string
    subtitle: string
    retakeTitle: string
    RetakeCta: Array<Blok>
    favoritesTitle: string
    favoritesSubtitle: string
    FavoritesCta: Array<Blok>
  }):{
    Questions: Array<Blok>
    RetakeCta: Array<Blok>
    FavoritesCta: Array<Blok>
    contentType: string
    handle: string
  }
  {
    let retakeCta = blok.RetakeCta.map(mapStory)
    let favoritesCta = blok.FavoritesCta.map(mapStory)
    
    return {
      contentType: 'guidedQuiz',
      // @ts-ignore
      data: {
        handle: get(blok, 'Handle'),
        questions: blok.Questions.map(mapStory),
        entryTitle: get(blok, 'EntryTitle'),
        title: get(blok, 'Title'),
        retakeTitle: get(blok, 'RetakeTitle'),
        retakeCta: retakeCta[0] || null,
        favoritesCta: favoritesCta[0] || null,
        subtitle: get(blok, 'Subtitle'),
        favoritesTitle: get(blok, 'FavoritesTitle'),
        favoritesSubtitle: get(blok, 'FavoritesSubtitle'),
      }
    }
  },
  question(blok: Blok & {
    contentType: string
    title: string
    subtitle: string
    multipleAnswers: boolean
    handle: string
    points: number
    Answers: Array<Blok>
  }):{
    Answers: Array<Blok>
    contentType: string
    handle: string
  } 
  {
    return {
      contentType: blok.component,
      // @ts-ignore
      // data: {
        title: get(blok, 'Title'),
        subtitle: get(blok, 'Subtitle'),
        multipleAnswers: get(blok, 'MultipleAnswers'),
        handle: get(blok, 'Handle'),
        points: get(blok, 'Points'),
        answers: blok.Answers.map(mapStory),
      // }
    }
  },
  answer(blok: Blok & {
    contentType: string
    Title: string
    Type: string
    PrioritizeAnswer: boolean
    SkipAnswer: boolean
    DisplayAlts: boolean
    Collections: string
    Filter: string
    Image: Array<Blok>
    AlternateImage: Array<Blok>
  }):{
    answers: Array<Blok>
    contentType: string
    handle: string
    image: object | void
    alternateImage: object | void
  } 
  {
    return {
      contentType: blok.component,
      // @ts-ignore
      // data: {
        title: get(blok, 'Title'),
        type: get(blok, 'Type'),
        prioritizeAnswer: get(blok, 'PrioritizeAnswer'),
        skipAnswer: get(blok, 'SkipAnswer'),
        displayAlts: get(blok, 'DisplayAlts'),
        collections: get(blok, 'Collections'),
        filter: get(blok, 'Filter'),
        image: {
          alt: get(blok,'Image.filename'),
          src: get(blok, 'Image.filename'),
        },
        altImage: {
          alt: get(blok,'AlternativeImage.filename'),
          src: get(blok, 'AlternativeImage.filename'),
        },
      // }
    }
  },
  twoColumn(blok: Blok & {
    contentType: string
    title: Array<any> | void
    subtitle: Array<any> | void
    Cards: Array<Blok>
    BackgroundColor: string
    NoPadding: Boolean
  }):{
    contentType: string
    cards: Array<Blok>
    BackgroundColor: string
    NoPadding: Boolean
  } 
  {
    return {
      contentType: blok.component,
      // @ts-ignore
      data: {
        title: get(blok, 'title[0].Text'),
        titleTypography: get(blok, 'title[0].HeadingStyle'),
        titleAlignment: get(blok, 'title[0].Alignment'),
        titleMarginBottom: get(blok, 'title[0].MarginBottom'),
        titleColor: get(blok, 'title[0].Color'),
        subtitle: get(blok, 'subtitle[0].Text'),
        subtitleTypography: get(blok, 'subtitle[0].HeadingStyle'),
        subtitleAlignment: get(blok, 'subtitle[0].Alignment'),
        subtitleMarginBottom: get(blok, 'subtitle[0].MarginBottom'),
        subtitleColor: get(blok, 'subtitle[0].Color'),
        subtitleFont: get(blok, 'subtitle[0].Font'),
        cards: blok.Cards.map(mapStory),
        backgroundColor: get(blok, 'BackgroundColor'),
        noPadding: get(blok, 'NoPadding'),
      }
    }
  },
  threeColumn(blok: Blok & {
    contentType: string
    Cards: Array<Blok>
  }):{
    contentType: string
    title: Array<any> | void
    cards: Array<Blok>
  } 
  {
    return {
      contentType: blok.component,
      // @ts-ignore
      data: {
        title: get(blok, 'title[0].Text'),
        titleTypography: get(blok, 'title[0].HeadingStyle'),
        titleAlignment: get(blok, 'title[0].Alignment'),
        titleMarginBottom: get(blok, 'title[0].MarginBottom'),
        titleColor: get(blok, 'title[0].Color'),
        cards: blok.Cards.map(mapStory)
      }
    }
  },
  brandCarousel(blok: Blok & {
    contentType: string
    BrandCards: Array<Blok>
  }):{
    contentType: string
    brandCards: Array<Blok>

  } 
  {
    return {
      contentType: blok.component,
      // @ts-ignore
      data: {
        brandCards: blok.BrandCards.map(mapStory),
        backgroundColor: get(blok, 'BackgroundColor')
      }
    }
  },
  brandCard(blok: Blok & {
    contentType: string
  }):{
    contentType: string | void
    url: string | void
    darkBackground: string | void
    image: MediaObject | void
  }
  {
    return {
        contentType: blok.component,
        url: get(blok, 'url'),
        image: {
          alt: get(blok, 'image.alt'),
          src: get(blok, 'image.filename'),
          title: get(blok, 'image.name')
        },
        darkBackground: get(blok, 'darkBackground'),
    }
  },
  landingPageCard(blok: Blok & {
    contentType: string
  }):{
    contentType: string | void
    productHandle: string | void
    title: Array<any> | void
    subTitle: Array<any> | void
    url: string | void
    type: string | void
    textColor: string | void
    showArrow: boolean | void
    backgroundImage: MediaObject | void
    size: string | void
    gradient: boolean | void
    backgroundSize: string | void
  }
  {
    return {
        contentType: blok.component,
        productHandle: get(blok, 'productHandle'),
        title: get(blok, 'Title[0]'),
        // @ts-ignore
        subtitle: get(blok, 'subtitle[0]'),
        url: get(blok, 'url'),
        backgroundImage: {
          alt: get(blok, 'backgroundImage.alt'),
          src: get(blok, 'backgroundImage.filename'),
          title: get(blok, 'backgroundImage.name')
        },
        textColor: get(blok, 'textColor'),
        showArrow: get(blok, 'showArrow'),
        size: get(blok, 'Size'),
        gradient: get(blok,'gradient'),
        type: get(blok, 'Type'),
        backgroundSize: get(blok, 'backgroundSize')
    }
  },
  navColumn(blok: Blok & {
    contentType: string
    navItems: Array<Blok>
  }):{
    contentType: string
    id: string
    data: {
      heading: string
      navItems: Array<Blok>
    }
  } 
  {
    return {
      contentType: blok.component,
      id: blok._uid,
      data: {
        heading: get(blok, 'heading'),
        navItems: blok.navItems.map(mapStory)
      }
    }
  },
  navCombinationLayout(blok: Blok & {
    contentType: string
    navItems: Array<Blok>
    banner: Array<Blok>
    twoRowNavItem: Array<Blok>
  }):{
    contentType: string
    navItems: Array<Blok>
    banners: Array<Blok>
    twoRowNavItems: Array<Blok>
  } 
  {
    return {
      contentType: blok.component,
      // @ts-ignore
      data: {
        navItems: blok.navItems.map(mapStory),
        banners: blok.banner.map(mapStory),
        twoRowNavItems: blok.twoRowNavItem.map(mapStory)
      }
    }
  },
  navFourColumnGrid(blok: Blok & {
    contentType: string
    navItems: Array<Blok>
  }):{
    contentType: string
    navItems: Array<Blok>
  } 
  {
    return {
      contentType: blok.component,
      // @ts-ignore
      data: {
        navItems: blok.navItems.map(mapStory)
      }
    }
  },
  navSingleRow(blok: Blok & {
    contentType: string
    navItems: Array<Blok>
  }):{
    contentType: string
    navItems: Array<Blok>
  } 
  {
    return {
      contentType: blok.component,
      // @ts-ignore
      data: {
        navItems: blok.navItems.map(mapStory)
      }
    }
  },
  navSection(blok: Blok & {
    title: string
    primaryNavTitle: string
    primaryNav: Array<any>
    secondaryNav: Array<any>
  }) {
    const navImage = {
      src: get(blok, 'mobileNavBackground.filename'),
      alt: get(blok, 'mobileNavBackground.alt')
    }
    return {
      contentType: blok.component,
      id: blok._uid,
      data: {
        title: blok.title,
        primaryNavTitle: blok.primaryNavTitle,
        primaryNav: blok.primaryNav.map(mapStory),
        secondaryNav: blok.secondaryNav.map(mapStory),
        image: (navImage.src) ? navImage : undefined
      }
    }
  },
  banner(blok: Blok & {
    messages: Array<Blok & {
      title: string
      linkText: string
      linkUrl: string
    }>
    date: Date
    autoscroll: boolean
  }) {
    const resolver = new RichTextResolver()
    const messages = blok.messages || []
    return {
      contentType: blok.component,
      data: {
        backgroundColor: get(blok, 'backgroundColor.value'),
        date: get(blok, 'date'),
        autoscroll: get(blok, 'autoscroll'),
        messages: messages.map((s) => {
          return {
            title: (s.title) ? resolver.render(s.title) : '',
            linkText: get(s, 'linkText'),
            linkUrl: get(s, 'linkUrl'),
          }
        })
      }
    }
  },
  swatchLink(blok: Blok) {
    return {
      contentType: blok.component,
      handle: blok._uid,
      data: {
        message: get(blok, 'title'),
        color: get(blok, 'color.value'),
        to: get(blok, 'url', '')
      }
    }
  },
  navItem(blok: Blok & {
    contentType: string
  }):{
    contentType: string | void
    externalLink: boolean
    title: Array<any> | void
    subTitle: Array<any> | void
    description: Array<any> | void
    linkUrl: string | void
    new: boolean
    featured: boolean
    image: MediaObject | void
  }
  {
    return {
        contentType: blok.component,
        // @ts-ignore
        navItem: {
          title: get(blok, 'Title'),
          subtitle: get(blok, 'Subtitle'),
          linkUrl: get(blok, 'linkUrl'),
          externalLink: get(blok, 'externalLink'),
          new: get(blok, 'new'),
          featured: get(blok, 'featured'),
          description: get(blok, 'Description'),
          image: {
            alt: get(blok, 'Image.alt'),
            src: get(blok, 'Image.filename'),
            title: get(blok, 'Image.name')
          },
      }
    }
  },
  review(blok: Blok & {
    numberOfReviews: string
  }) {
    const numberOfReviews = get(blok, 'numberOfReviews')
    return {
      contentType: blok.component,
      data: {
        numberOfReviews: (numberOfReviews) ? parseInt(numberOfReviews) : undefined,
        layout: get(blok, 'layout'),
        productHandle: get(blok, 'productHandle'),
        title: get(blok, 'title[0]'),
        staticReviews: get(blok, 'staticReviews', [])
      }
    }
  },
  ringOverlayCard(blok: Blok & {
    contentType: string
    cta: Cta
  }):{
    contentType: string | void
    backgroundImage: MediaObject | void
    overlayImage: MediaObject | void
    cta: Cta | {}
    url: string | void
  }
  {
    return {
        contentType: blok.component,
        backgroundImage: {
          alt: get(blok, 'backgroundImage.alt'),
          src: get(blok, 'backgroundImage.filename'),
          title: get(blok, 'backgroundImage.name')
        },
        overlayImage: {
          alt: get(blok, 'overlayImage.alt'),
          src: get(blok, 'overlayImage.filename'),
          title: get(blok, 'overlayImage.name')
        },
        cta: {
          buttonSize: get(blok, 'CTA[0].buttonSize'),
          text: get(blok, 'CTA[0].text'),
          type: get(blok, 'CTA[0].type'),
          url: get(blok, 'CTA[0].url'),
          externalUrl: get(blok, 'CTA[0].externalUrl'),
          isDisabled: get(blok, 'CTA[0].isDisabled'),
          customColor: get(blok, 'CTA[0].customColor'),
          marginBottom: get(blok, 'CTA[0].marginBottom'),
        },
        url: get(blok, 'url'),
    }
  },
  column(blok: Blok & {
    contentType: string
    card: Array<Blok>
  }):{
    contentType: string | void
    data: {
      textAlignment: string | void
      mobileTextAlignment: string | void
      title: string | void
      titleTypography: string | void
      titleMarginBottom: string | void
      titleColor: string | void
      subtitle: string | void
      subtitleTypography: string | void
      subtitleMarginBottom: string | void
      subtitleColor: string | void
      numberOfColumns: number | void
      description: string | void
      descriptionTypography: string | void
      descriptionMarginBottom: string | void
      descriptionColor: string | void
      backgroundImage: object | void
    card: Array<Blok>
    }
  }
  {
    return {
      contentType: blok.component,
      data: {
        title: get(blok, 'Title[0].Text'),
        titleTypography: get(blok, 'Title[0].HeadingStyle'),
        titleMarginBottom: get(blok, 'Title[0].MarginBottom'),
        titleColor: get(blok, 'Title[0].Color'),
        subtitle: get(blok, 'Subtitle[0].Text'),
        subtitleTypography: get(blok, 'Subtitle[0].HeadingStyle'),
        subtitleMarginBottom: get(blok, 'Subtitle[0].MarginBottom'),
        subtitleColor: get(blok, 'Subtitle[0].Color'),
        description: get(blok, 'Description[0].Text'),
        descriptionTypography: get(blok, 'Description[0].HeadingStyle'),
        descriptionMarginBottom: get(blok, 'Description[0].MarginBottom'),
        descriptionColor: get(blok, 'Description[0].Color'),
        textAlignment: get(blok, 'textAlignment'),
        mobileTextAlignment: get(blok, 'mobileTextAlignment'),
        numberOfColumns: parseInt(get(blok, 'numberOfColumns')),
        backgroundImage: {
          alt: get(blok, 'backgroundImage.alt'),
          src: get(blok, 'backgroundImage.filename'),
          title: get(blok, 'backgroundImage.name')
        },
        card: mapStory(blok.card[0])
      }
    }
  },
  row(blok: Blok & {
    contentType: string
    column: Array<Blok>
  }):{
    contentType: string | void
    anchor: string | void
    numberOfColumns: number | void
    column: Array<Blok> | void
    textAlignment: string | void
    mobileTextAlignment: string | void
    title: string | void
    titleTypography: string | void
    titleMarginBottom: string | void
    titleColor: string | void
    subtitle: string | void
    subtitleTypography: string | void
    subtitleMarginBottom: string | void
    subtitleColor: string | void
    description: string | void
    descriptionTypography: string | void
    descriptionMarginBottom: string | void
    descriptionColor: string | void
    backgroundColor: string | void
    cardBoundary: string | void
    desktopBackgroundImage: object | void
    mobileBackgroundImage: object | void
    backgroundSize: string | void
    mobileAndDesktopHidden: boolean | void
    paddingTop: number | void
    paddingBottom: number | void
    columnSpacing: number | void
    runAbTest: boolean | void
    abTestName: string | void
    abTestVariant: string | void
    columnAlign: string | void
    middleImage: string | void
  }
  {
    return {
      contentType: blok.component,
      // @ts-ignore
      data: {
        anchor: get(blok, 'anchor'),
        numberOfColumns: parseInt(get(blok, 'numberOfColumns')),
        textAlignment: get(blok, 'textAlignment'),
        mobileTextAlignment: get(blok, 'mobileTextAlignment'),
        title: get(blok, 'title[0].Text'),
        titleTypography: get(blok, 'title[0].HeadingStyle'),
        titleMarginBottom: get(blok, 'title[0].MarginBottom'),
        titleColor: get(blok, 'title[0].Color'),
        subtitle: get(blok, 'subtitle[0].Text'),
        subtitleTypography: get(blok, 'subtitle[0].HeadingStyle'),
        subtitleMarginBottom: get(blok, 'subtitle[0].MarginBottom'),
        subtitleColor: get(blok, 'subtitle[0].Color'),
        subtitleFont: get(blok, 'subtitle.0.Font'),
        description: get(blok, 'description[0].Text'),
        descriptionTypography: get(blok, 'description[0].HeadingStyle'),
        descriptionMarginBottom: get(blok, 'description[0].MarginBottom'),
        descriptionColor: get(blok, 'description[0].Color'),
        backgroundColor: get(blok, 'backgroundColor'),
        cardBoundary: get(blok, 'cardBoundary'),
        desktopBackgroundImage: {
          alt: get(blok, 'backgroundImage[0].desktop.alt'),
          src: get(blok, 'backgroundImage[0].desktop.filename'),
          title: get(blok, 'backgroundImage[0].desktop.name')
        },
        mobileBackgroundImage: {
          alt: get(blok, 'backgroundImage[0].mobile.alt'),
          src: get(blok, 'backgroundImage[0].mobile.filename'),
          title: get(blok, 'backgroundImage[0].mobile.name')
        },
        backgroundSize: get(blok, 'backgroundSize'),
        mobileAndDesktopHidden: get(blok, 'mobileAndDesktopHidden'),
        paddingTop: parseInt(get(blok, 'paddingTop')),
        paddingBottom: parseInt(get(blok, 'paddingBottom')),
        column: blok.column.map(mapStory),
        columnSpacing: parseInt(get(blok, 'columnSpacing')),
        runAbTest: get(blok, 'runAbTest'),
        abTestName: get(blok, 'abTestName'),
        abTestVariant: get(blok, 'abTestVariant'),
        columnAlign: get(blok, 'columnAlign'),
        middleImage: get(blok, 'middleImage.filename'),
      }
    }
  },
  inputForm(blok: Blok & {
    contentType: string
    formInputs: Array<Blok>
  }): {
    contentType: string | void
    formTitle: string | void
    formType: string | void
    endpoint: string | void
    formInputs: Array<any> | void
    showWholesaleNetsuiteForm: boolean | void
  }
  {
    return {
        contentType: blok.component,
        // @ts-ignore
        data: {
          formTitle: get(blok, 'formTitle'),
          formType: get(blok, 'formType'),
          endpoint: get(blok, 'endpoint'),
          formInputs: blok.formInputs.map(mapStory),
          listId: get(blok, 'listId'),
          showWholesaleNetsuiteForm: get(blok, 'showWholesaleNetsuiteForm'),
        }
    }
  },
  formInput(blok: Blok & {
    contentType: string
    inputOptions: Array<Blok & { Title: string }>
  }):{
    contentType: string | void
    formFieldTitle: string | void
    id: string | void
    required: boolean | void
    desktopWidth: string | void
    inputType: string | void
    resizable: string | void
    inputOptions: Array<Blok> | void
  }
  {
    return {
      contentType: blok.component,
      // @ts-ignore
      sys: {
        id: get(blok, 'id')
      },
      fields: {
        formFieldTitle: get(blok, 'formFieldTitle'),
        id: get(blok, 'id'),
        required: get(blok, 'required'),
        desktopWidth: get(blok, 'desktopWidth'),
        inputType: get(blok, 'inputType'),
        resizable: get(blok, 'resizable'),
        inputOptions: blok.inputOptions.map(e => e.Title)
      }
    }
  },
  ctaGrid(blok: Blok & {
    EntryTitle: string,
    Cards: Array<Blok>
  }) {
    return {
      contentType: blok.component,
      handle: blok._uid,
      data: {
        // @ts-ignore
        cards: blok.Cards.map(mapStory)
      }
    }
  },
  contentCard(blok: Blok) {
    const responsiveImage = get(get(blok, 'Image', []).map(mapStory), '0')
    const cta = get(get(blok, 'CTA', []).map(mapStory), '0', {})
    const resolver = new RichTextResolver()
    const subtitle = get(blok, 'Subtitle[0].RichText') || get(blok, 'Subtitle.0.Text')

    let result = {
      contentType: blok.component,
      handle: blok._uid,
        backgroundColor: get(blok, 'BackgroundColor'),
        backgroundImage: {
          url: get(blok, 'BackgroundImage.filename'),
          alt: get(blok, 'BackgroundImage.alt'),
          title: get(blok, 'BackgroundImage.name')
        },
        ...responsiveImage,
        additionalImages: get(blok, 'AdditionalImages', []).map(mapStory),
        type: get(blok, 'component'),
        title: get(blok, 'Title.0.Text'),
        titleFont: get(blok, 'Title.0.Font'),
        titleMarginBottom: get(blok, 'Title.0.MarginBottom'),
        titleTypography: get(blok, 'Title.0.HeadingStyle'),
        titleColor: get(blok, 'Title.0.Color'),
        textAlignment: get(blok, 'Title.0.Alignment'),
        mobileTextAlignment: get(blok, 'Title.0.MobileAlignment'),
        titleHiddenDesktop: get(blok, 'Title.0.HiddenDesktop'),
        titleHiddenMobile: get(blok, 'Title.0.HiddenMobile'),
        featured: get(blok, 'Featured'),
        subtitle: (subtitle) ? resolver.render(subtitle) : '',
        subtitleTypography: get(blok, 'Subtitle.0.HeadingStyle'),
        subtitleMarginBottom: get(blok, 'Subtitle.0.MarginBottom'),
        subtitleFont: get(blok, 'Subtitle.0.Font'),
        subtitleColor: get(blok, 'Subtitle.0.Color'),
        description: get(blok, 'Description.0.Text'),
        descriptionTypography: get(blok, 'Description.0.HeadingStyle'),
        descriptionMarginBottom: get(blok, 'Description.0.MarginBottom'),
        descriptionColor: get(blok, 'Description.0.Color'),
        belowButtonText: get(blok, 'BelowButtonText.0.Text'),
        belowButtonTextColor: get(blok, 'BelowButtonText.0.Color'),
        belowButtonTextMarginBottom: get(blok, 'BelowButtonText.0.MarginBottom'),
        cardClickable: get(blok, 'CardClickable'),
        newBadge: get(blok, 'NewBadge'),
        productDescription: get(blok, 'productDescription'),
        productImage: get(blok, 'productImage')
    }

    if (get(blok, 'TitleLogo.filename')) {
      result = {
        ...result,
        titleLogo: {
          src: get(blok, 'TitleLogo.filename'),
          alt: get(blok, 'Title.alt'),
        }
      }
    }

    if (get(cta, 'text') || get(cta, 'url')) {
      result = {
        ...result,
        cta
      }
    }

    if (get(blok, 'Video.filename')) {
      return {
        ...result, 
        video: {
          src: get(blok, 'Video.filename')
        }
      }
    } else {
      return result
    }
  },
  detailsCard(blok: Blok) {
    return {
      contentType: blok.component,
      handle: blok._uid,
      details: get(blok, 'Details', []).map(mapStory)
    }
  },
  featuredTextRow(blok: Blok) {
    let result =  {
      contentType: blok.component,
      handle: blok._uid,
      title: get(blok, 'title.0.Text'),
      titleFont: get(blok, 'title.0.Font'),
      titleMarginBottom: get(blok, 'title.0.MarginBottom'),
      titleTypography: get(blok, 'title.0.HeadingStyle'),
      titleColor: get(blok, 'title.0.Color'),
      textAlignment: get(blok, 'title.0.Alignment'),
      mobileTextAlignment: get(blok, 'title.0.MobileAlignment'),
      subtitle: get(blok, 'subtitle.0.Text'),
      subtitleFont: get(blok, 'subtitle.0.Font'),
      subtitleMarginBottom: get(blok, 'subtitle.0.MarginBottom'),
      subtitleTypography: get(blok, 'subtitle.0.HeadingStyle'),
      subtitleColor: get(blok, 'subtitle.0.Color'),
      description: get(blok, 'description.0.Text'),
      descriptionFont: get(blok, 'description.0.Font'),
      descriptionMarginBottom: get(blok, 'description.0.MarginBottom'),
      descriptionTypography: get(blok, 'description.0.HeadingStyle'),
      descriptionColor: get(blok, 'description.0.Color'),
    }

    return result;
  },
  cta(blok: Blok) {
    return {
      contentType: 'Btn',
      text: get(blok, 'text'),
      url: get(blok, 'url'),
      externalUrl: get(blok, 'externalUrl'),
      type: get(blok, 'type'),
      customColor: get(blok, 'customColor'),
      buttonSize: get(blok, 'buttonSize'),
      marginBottom: get(blok, 'marginBottom'),
    }
  },
  responsiveImage(blok: Blok) {
    return {
      contentType: blok.component,
      image: {
        src: get(blok, 'desktop.filename'),
        alt: get(blok, 'desktop.alt'),
        title: get(blok, 'name')
      },
      mobileImage: {
        src: get(blok, 'mobile.filename'),
        alt: get(blok, 'mobile.alt'),
        title: get(blok, 'name')
      },
      imageWidth: get(blok, 'width'),
      imageHeight: get(blok, 'height')
    }
  },
  backgroundContentCard(blok: Blok & {
    EntryTitle: string,
    Title: string,
    Description: string,
    BackgroundImage: {
      filename: string,
      alt: string,
    },
    Type: string,
    CTA: Array<Cta & {
      url: {
        url: string,
        linktype: string
      }
    }>,
    navTile: boolean
  }) {
    let cta = get(blok, 'CTA.0', {})

    if (Object.keys(cta).length > 0) {
      cta = {
        ...cta,
        url: get(cta, 'url')
      }
    }

    return {
      contentType: blok.component,
      handle: blok._uid,
      type: get(blok, 'component'),
      entryTitle: blok.EntryTitle,
      title: get(blok, 'Title[0].Text'),
      subtitle: get(blok, 'Subtitle[0].Text'),
      description: get(blok, 'Description[0].Text'),
      backgroundImage: {
        src: get(blok, 'BackgroundImage.filename', ''),
        alt: get(blok, 'BackgroundImage.alt', '')
      },
      cta,
      navTile: blok.navTile
    }
  },
  globalModal(blok: Story<{
    EntryTitle: string,
    sections: Array<Blok>
    showOnlyOnce: boolean
  }>) {
    const sections = blok.content.sections || []
    return {
      contentType: "modal",
      handle: blok.id,
      data: {
        entryTitle: get(blok, 'EntryTitle'),
        sections: sections.map(mapStory),
        showOnlyOnce: get(blok, 'showOnlyOnce')
      }
    }
  },
  accordion(blok: Blok & {
    EntryTitle: string
    Sections: Array<Blok & {
      description: any
      title: string
    }>
  }) {
    const resolver = new RichTextResolver()
    const Sections = blok.Sections || []
    return {
      contentType: blok.component,
      handle: blok._uid,
      data: {
        entryTitle: blok.EntryTitle,
        title: blok.EntryTitle,
        sections: Sections.map((s) => {
          return {
            contentType: s.component,
            entryTitle: s.entryTitle,
            title: s.title || s.entryTitle,
            description: (s.description) ? resolver.render(s.description) : ''
          }
        })
      }
    }
  },
  contentCarousel(blok: Blok & {
    EntryTitle: string,
    title: string | void
    titleTypography: string | void
    titleMarginBottom: string | void
    titleColor: string | void
    Sections: Array<Blok>
    SideGradients: boolean,
    gradientHeight: string | void
    buttonColor: string | void
    backgroundColor: string | void
  }) {
    const sections = blok.Sections || []

    return {
      contentType: blok.component,
      handle: blok._uid,
      data: {
        entryTitle: get(blok, 'EntryTitle'),
        title: get(blok, 'title.0.Text'),
        titleFont: get(blok, 'title.0.Font'),
        titleMarginBottom: get(blok, 'title.0.MarginBottom'),
        titleTypography: get(blok, 'title.0.HeadingStyle'),
        titleColor: get(blok, 'title.0.Color'),
        sections: sections.map(mapStory),
        sideGradients: get(blok, 'sideGradients'),
        gradientHeight: get(blok, 'gradientHeight'),
        buttonColor: get(blok, 'buttonColor'),
        cta: get(get(blok, 'cta', []).map(mapStory), '0', {}),
        backgroundColor: get(blok, 'backgroundColor'),
      }
    }
  },
  collectionGrid(blok: Blok & {
    EntryTitle: string,
    handle: string,
    truncate: boolean | void,
    maxProducts: number | void,
    enableFilterBar: boolean | void
  }) {
    return {
      contentType: blok.component,
      handle: blok._uid,
      data: {
        collection: blok.handle,
        truncate: blok.truncate || undefined,
        maxProducts: blok.maxProducts ? toNumber(blok.maxProducts) : undefined,
        enableFilterBar: blok.enableFilterBar || undefined,
      }
    }
  },
  bundle(blok: Blok & {
    EntryTitle: string,
    productHandle: string
    DisableFilterBar: Boolean
  }) {
    return {
      contentType: blok.component,
      handle: blok._uid,
      data: {
        productHandle: blok?.productHandle,
        disableFilterBar: blok?.DisableFilterBar
      }
    }
  },
  miniProductDetailPage(blok: Blok & {
    EntryTitle: string,
    productHandle: string
  }) {
    return {
      contentType: blok.component,
      handle: blok._uid,
      data: {
        productHandle: blok?.productHandle
      }
    }
  },
  featuredProducts(blok: Blok & {
    EntryTitle: string
  }) {
    const responsiveImage = get(get(blok, 'Image', []).map(mapStory), '0')
    const cta = get(get(blok, 'CTA', []).map(mapStory), '0')

    let result = {
      contentType: blok.component,
      handle: blok._uid,
      data: {
        type: get(blok, 'Type'),
        collections: get(blok, 'Collections'),
        products: get(blok, 'Products'),
        collection: get(blok, 'Collection'),
        title: get(blok, 'Title.0.Text'),
        titleFont: get(blok, 'Title.0.Font'),
        titleMarginBottom: get(blok, 'Title.0.MarginBottom'),
        titleTypography: get(blok, 'Title.0.HeadingStyle'),
        titleColor: get(blok, 'Title.0.Color'),
        subtitle: get(blok, 'Subtitle.0.Text'),
        subtitleTypography: get(blok, 'Subtitle.0.HeadingStyle'),
        subtitleMarginBottom: get(blok, 'Subtitle.0.MarginBottom'),
        subtitleFont: get(blok, 'Subtitle.0.Font'),
        subtitleColor: get(blok, 'Subtitle.0.Color'),
        centerCarousel: get(blok, 'CenterCarousel'),
        carouselButtonColor: get(blok, 'carouselButtonColor'),
        widthFilter: get(blok, 'WidthFilter'),
        ...responsiveImage,
        textFullWidth: get(blok, 'TextFullWidth'),
        textOverAccordion: get(blok, 'TextOverAccordion'),
        imageSide: get(blok, 'ImageSide'),
        column: get(blok, 'columns', []).map(mapStory),
        backgroundColor: get(blok, 'BackgroundColor')
      }
    }

    if (get(cta, 'text') || get(cta, 'url')) {
      result.data = {
        ...result.data,
        cta
      }
    }

    return result
  },
  video(blok: Blok & {
    EntryTitle: string
  }) {
    return {
      contentType: blok.component,
      handle: blok._uid,
      data: {
        src: get(blok, 'Video.filename'),
        poster: get(blok, 'Poster.filename')
      }
    }
  },
  socialMediaGallery(blok: Blok) {
    return {
      contentType: blok.component,
      handle: blok._uid,
      data: {
        title: get(blok, 'Title') || undefined,
        cta: get(blok, 'Cta') || undefined,
        links: get(blok, 'links', []).map(mapStory)
      }
    }
  },
  socialLinkCard(blok: Blok) {
    return {
      contentType: blok.component,
      handle: blok._uid,
      id: blok._uid,
      data: {
        title: get(blok, 'title'),
        url: get(blok, 'link'),
        backgroundImage: {
          src: get(blok, 'backgroundImage.filename'),
          alt: get(blok, 'backgroundImage.alt'),
          title: get(blok, 'backgroundImage.title'),
          name: get(blok, 'backgroundImage.name')
        },
        attribution: get(blok, 'attribution')
      }
    }
  },
  linkCard(blok: Blok) {
    return {
      contentType: blok.component,
      handle: blok._uid,
      id: blok._uid,
      data: {
        title: get(blok, 'title'),
        url: get(blok, 'link'),
        backgroundImage: {
          src: get(blok, 'backgroundImage.filename'),
          alt: get(blok, 'backgroundImage.alt'),
          title: get(blok, 'backgroundImage.title'),
          name: get(blok, 'backgroundImage.name')
        },
        bold: get(blok, 'bold')
      }
    }
  },
  imageCardCta(blok: Blok) {
    return {
      contentType: blok.component,
      handle: blok._uid,
      data: {
        title: get(blok, 'title'),
        subtitle: get(blok, 'subtitle'),
        url: get(blok, 'link'),
        gradient: get(blok, 'gradient'),
        backgroundImage: {
          src: get(blok, 'backgroundImage.filename'),
          alt: get(blok, 'backgroundImage.alt'),
          name: get(blok, 'backgroundImage.name'),
          title: get(blok, 'backgroundImage.title')
        }
      }
    }
  },
}
function mapPage(story: Story<Blok & {
  Description: string
  PageTheme: string
  Title: string
}>): {
  pageType: string
  title: string
  handle: string
  description: string
  pageTheme: string
  sections: Array<Blok>
  hideProductRecommendations: boolean | void
} {
  // console.log('sb page', story)
  return {
    pageType: 'page',
    handle: story.full_slug,
    title: story.content.Title,
    description: story.content.Description,
    pageTheme: story.content.PageTheme,
    sections: get(story.content, 'Sections', []).map(mapStory),
    hideProductRecommendations: get(story.content, 'hideProductRecommendations')
  }
}
interface GlobalNotification {
  active: boolean;
  contentType?: string;
  data?: {
    autoscroll: boolean
    date: string
  }
}
function mapNav(story: Blok & { globalNotification: Array<Blok> }): {
  image: { src: string; alt: string; }
  navSections: any
  globalNotification: GlobalNotification
} {
  const banner = story.globalNotification[0]
  let globalNotification: GlobalNotification = {
    active: !!banner,
  }
  if (banner) {
    Object.assign(globalNotification, mapStory(clone(banner)))
  }
  return {
    navSections: get(story, 'navSections').map(mapStory),
    globalNotification,
    image: {
      src: get(story, 'logo.filename'),
      alt: get(story, 'logo.alt')
    }
  }
}
export function mapStory(story: Story<Blok> | Blok) {
  const page = story as Story<Blok>
  if (page && page.content && page.full_slug) {
    // handle global mappings
    switch (page.full_slug) {
      case 'globalHeader':
        return {
          contentType: camelCase(page.full_slug),
          data: mapNav(page.content as Blok & { globalNotification: Array<Blok> })
        }
      case 'ring-size-modal':
        return {
          contentType: 'globalModal',
          sections: get(page, 'content.Sections', []).map(mapStory)
        }
      default:
        return mapPage(page as Story<Blok & { Description: string; PageTheme: string; Title: string; }>)
    }
  } else {
    // match a component type, for common subfields, use a utility function!
    const component = get(story, 'content.component', '') || get(story, 'component', '')
    const contentType = camelCase(component)
    const mapper = typeMap[contentType]
    if (mapper && story) {
      return mapper(story)
    } else {
      // console.warn('[map-story] unknown type', contentType, story)
      const errType = `UnknownTypeOf${contentType}`
      return {
        contentType: 'NacelleReference',
        id: uniqueId(errType),
        _story: story
      }
    }
  }
}
export function mapStoryContent(input: { fields: Story<Blok> }) {
  const data = clone(input)
  const metafields = Object.keys(data).filter((key) => key !== 'fields')
  return clone({
    ...pick(data, metafields),
    ...mapStory(data.fields)
  })
}
interface MediaObject {
  alt: string
  src: string
  title: string
}
interface Cta {
  buttonSize: string
  entryTitle: string
  text: string
  type: string
  url: string
}
// used to identify top-level content items, these contain some combination of
// bloks. here, T extends the Blok type that start the document tree for the
// story object.
export interface Story<T> {
  slug: string
  path: string
  full_slug: string
  lang: string
  last_author: { userid: string }
  published: boolean
  id: number
  content: Blok & T
}
// in your block mapping, you can extend the blok definition to include whatever
// fields that particular type has inline or as an extension of this interface.
// ```
// function myType(blok: Bloc & { myField: string }) {}
// interface MyType extends Blok {}
// ```
export interface Blok {
  _uid: string
  component: string
  entryTitle: string
}
