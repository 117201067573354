import Vue from 'vue'
export const RebuyCollectionCarousel = Vue.component(
  'rebuy-collection-carousel-async-wrapper',
  () => import('./CollectionCarousel.sync')
)
export const RebuySimilarProductsCarousel = Vue.component(
  'rebuy-similar-products-carousel-async-wrapper',
  () => import('./SimilarProductsCarousel.sync')
)
export const RebuyTrendingProductsCarousel = Vue.component(
  'rebuy-trending-products-carousel-async-wrapper',
  () => import('./TrendingProductsCarousel.sync')
)
export const RebuyRelatedProducts = Vue.component(
  'rebuy-related-products-async-wrapper',
  () => import('./RelatedProducts.sync')
)
