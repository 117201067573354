import createShopifyCheckoutClient from '@nacelle/shopify-checkout';

export default function (context, inject) {
  const checkoutClient = createShopifyCheckoutClient({
    storefrontCheckoutToken: process.env.SHOPIFY_STOREFRONT_CHECKOUT_TOKEN,
    customEndpoint: process.env.SHOPIFY_STOREFRONT_API_ENDPOINT
  });

  inject('shopifyCheckout', checkoutClient);
}
