import BxGy from '~/utils/BxGy'
import clone from 'lodash/clone'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import isArray from 'lodash/isArray'
import sleep from '~/utils/sleep'
import uniq from 'lodash/uniq'
import { engravingKeys, feeHandles } from '~/utils/cartHelper'
import { setFlag } from '~/utils/userFlags'
/**
 * @param {object} options
 * @param {object[]} options.lineItems
 * @param {string} options.handle
 */
export const getLineItemIdByHandle = ({ lineItems, handle }) => {
  let id
  for (let lineItem of lineItems) {
    if (lineItem.handle === handle) {
      id = lineItem.id
      break
    }
  }
  return id
}
/**
 * @param {object} options
 * @param {object[]} options.lineItems
 * @param {string} options.id
 */
export const getLineItemById = ({ lineItems, id }) => {
  let result
  for (let lineItem of lineItems) {
    if (lineItem.id === id) {
      result = lineItem
      break
    }
  }
  return result
}
/**
 * @param {object} options
 * @param {string[]} options.engravingKeys
 * @param {object[]} options.lineItems
 * @param {string} options.id
 */
export const lineItemHasProps = ({ item, keys }) => {
  let result = null
  if (item && isArray(item.customAttributes) && isArray(keys)) {
    result = []
    for (let { key } of item.customAttributes) {
      if (keys.includes(key)) {
        result.push(true)
      }
    }
  }
  return result ? (result.length === keys.length) : false
}
/**
 * @param {object} options
 * @param {object[]} options.lineItems
 * @param {string} options.id
 */
const lineItemHasBraceletSleeve = (lineItem) => {
  // console.log('has bracelet', lineItem.handle, lineItem.customAttributes)
  let result = false
  if (
    lineItem &&
    lineItem.handle &&
    lineItem.handle.includes('bracelet') &&
    isArray(lineItem.customAttributes)
  ) {
    for (let { key } of lineItem.customAttributes) {
      if (key === 'sleeve') {
        result = true
      }
    }
  }
  return result
}
// cached fee products
let feeProductCache = {}
/**
 */
const updateFees = async ({
  store,
  feeHandle,
  feeItem,
  feeQuantity,
  props,
  customAttributes
}) => {
  // console.log('[cart-manager]', feeHandle, !!feeItem, feeQuantity)
  if ((feeQuantity === 0) && feeItem) {
    store.commit('cart/removeLineItemMutation', feeItem)
  } else if (feeQuantity > 0) {
    if (feeItem) {
      // set quantity if needed
      store.commit('cart/setQuantityMutation', {
        id: feeItem.id,
        quantity: feeQuantity
      })
    } else {
      // add fee to cart with quantity
      if (!feeProductCache[feeHandle]) {
        let product = await store.$api.getProduct({
          handle: feeHandle
        })
        feeProductCache[feeHandle] = {
          ...product,
          variant: get(product, 'variants[0]'),
        }
      }
      const feeObj = {
        ...clone(feeProductCache[feeHandle]),
        customAttributes: customAttributes || [],
        props: props || [],
        quantity: feeQuantity
      }
      await store.dispatch('cart/addLineItem', feeObj)
    }
  }
}
/**
 * loop over the line items in the cart and determine which fees (if any) apply
 * and update the cart to match
 *
 * @param {object} store
 * @param {string} [type]
 * @param {string} handle
 */
const applyFees = async (store, type, handle) => {
  // console.log('[cart-manager]', { type, handle, feeHandles })
  if (feeHandles.includes(handle)) {
    return
  }
  const lineItems = get(store, 'state.cart.lineItems')

  let engravingFeeItem = false
  let engravingBundleFeeItem = false
  let engravingFeeDiscountItem = false
  let braceletFeeItem = false
  let engravingQuantity = 0
  let engravingBundleQuantity = 0
  let engravingDiscountQuanity = 0
  let engravingDiscount = 0
  let braceletQuantity = 0
  // add _lineGiftWithPurchase to the bundle engraving item that corresponds to
  // the bundle. track another type quantity, so all bundle fees can be tracked
  // and discounted together.
  for (let item of lineItems) {
    const hasEngravingKeys = lineItemHasProps({ item, keys: engravingKeys })
    const hasBundleEngraving = lineItemHasProps({ item, keys: [ '_bundle_engraving_data' ] })
    const hasFreeBundleEngraving = item && item.tags.includes('free-bundle-engraving')
    if (hasBundleEngraving && hasFreeBundleEngraving) {
      engravingBundleQuantity += item.quantity || 1
    } else if (hasEngravingKeys || hasBundleEngraving) {
      if (lineItemHasProps({ item, keys: [ '_engravingDiscount' ] })) {
        engravingDiscountQuanity += item.quantity || 1
        if (item.customAttributes) {
          engravingDiscount = item.customAttributes.find((obj) => {
            return obj.key == "_engravingDiscount"
          }).value
        }
      } else {
        engravingQuantity += item.quantity || 1
      }
    }
    if (lineItemHasBraceletSleeve(item) && !item.handle.includes("star-wars-mod")) {
      braceletQuantity += item.quantity || 1
    }
    if (item.handle === 'engraving-fee') {
      // discount item here
      if (
        lineItemHasProps({ item, keys: [ '_engravingDiscount' ] })
      ) {
        engravingFeeDiscountItem = item
      } else if (
        lineItemHasProps({ item, keys: [ '_lineGiftWithPurchase' ] })
      ) {
        engravingBundleFeeItem = item
      } else {
        engravingFeeItem = item
      }

    }
    if (item.handle === 'bracelet-sleeve-fee') {
      braceletFeeItem = item
    }
  }
  try {
    await updateFees({
      store,
      feeHandle: 'engraving-fee',
      feeItem: engravingFeeItem,
      feeQuantity: engravingQuantity
    })
    await updateFees({
      store,
      feeHandle: 'engraving-fee',
      feeItem: engravingBundleFeeItem,
      feeQuantity: engravingBundleQuantity,
      customAttributes: [{
        key: '_lineGiftWithPurchase'
      }]
    })
    await updateFees({
      store,
      feeHandle: 'engraving-fee',
      feeItem: engravingFeeDiscountItem,
      feeQuantity: engravingDiscountQuanity,
      customAttributes: [{
        key: '_engravingDiscount',
        value: engravingDiscount.toString()
      }],
    })
    await updateFees({
      store,
      feeHandle: 'bracelet-sleeve-fee',
      feeItem: braceletFeeItem,
      feeQuantity: braceletQuantity
    })
  } catch (err) {
    console.error(err)
  }
}
// cached product handle lists
let bogoCollectionsCache = {}
const fetchCollectionHandles = async (store, handle) => {
  if (!bogoCollectionsCache[handle]) {
    try {
      const collection = await store.$nacelle.data.collection({
        handle
      })
      for (let productList of collection.productLists) {
        if (productList.slug === 'default') {
          bogoCollectionsCache[handle] = productList.handles
          break
        }
      }
    } catch (err) {
      console.error(err)
    }
  }
  return [...bogoCollectionsCache[handle]]
}
/**
 * @param {object} store
 * @param {string} type
 */
const applyBogo = async (store, type) => {
  const {
    bogo,
    bogoCustomerBuysCollections,
    bogoCustomerGetsCollections,
    bogoCustomerBuysQuantity,
    bogoCustomerBuysThreshold,
    bogoCustomerGetsQuantity,
    bogoCustomerGetsDiscountDecimal,
    bogoUsesPerOrderLimit,
    lineItems,
  } = store.state.cart
  const feedbackTypes = [
    'cart/removeLineItemProperties',
    'cart/setLineItemProperty',
  ]
  if (bogo && !feedbackTypes.includes(type)) {
    let buysHandles = []
    let getsHandles = []
    for (let handle of bogoCustomerBuysCollections) {
      const handles = await fetchCollectionHandles(store, handle)
      if (handles && handles.length) {
        buysHandles.push(...handles)
      }
    }
    for (let handle of bogoCustomerGetsCollections) {
      const handles = await fetchCollectionHandles(store, handle)
      if (handles && handles.length) {
        getsHandles.push(...handles)
      }
    }
    // remove bogo labels before we compute anything
    for (let { id, customAttributes } of lineItems) {
      if (customAttributes && customAttributes.length) {
        store.commit('cart/removeLineItemProperties', {
          id,
          keys: ['_local_bogo']
        })
      }
    }
    await sleep(1)
    const bxgy = new BxGy({
      buysHandles: uniq(buysHandles),
      buysQuantity: bogoCustomerBuysQuantity,
      buysThreshold: bogoCustomerBuysThreshold,
      getsDiscountDecimal: bogoCustomerGetsDiscountDecimal,
      getsQuantity: bogoCustomerGetsQuantity,
      getsHandles: uniq(getsHandles),
      lineItems,
      usesLimit: bogoUsesPerOrderLimit,
    })
    for (let item of bxgy.result) {
      if (item.hasBogoDiscount) {
        // set a local BOGO flag on the line item
        // console.log('bogo', item)
        try {
          store.commit('cart/setLineItemProperty', {
            id: item.id,
            prop: item.prop
          })
          await sleep(1)
        } catch (err) {
          console.error(err)
        }
      }
    }
  }
}
/**
 * track/update addon quantities so they match the corresponding bundle
 */
const applyAddonSync = (store, type) => {
  //console.log('addon', type)
  const allowedTypes = [
    'cart/decrementLineItemMutation',
    'cart/incrementLineItemMutation',
    'cart/removeLineItemMutation',
  ]
  if (allowedTypes.includes(type)) {
    const { lineItems } = store.state.cart
    const taggedItems = lineItems.filter(({ customAttributes }) => {
      return customAttributes?.find(({ key }) => key === '_local_addon_gwp')
    })
    const bundleGroups = groupBy(taggedItems, ({ customAttributes }) => {
      const attr = customAttributes?.find(({ key }) => key === '_local_bundle_id')
      return attr && attr.value
    })
    for (let [ groupId, products ] of Object.entries(bundleGroups)) {
      let qualifier
      let gifts = []
      for (let item of products) {
        const { customAttributes } = item
        if (customAttributes.find(({ key }) => key === '_lineGiftWithPurchase')) {
          gifts.push(item)
        } else {
          qualifier = item
        }
      }
      const { quantity } = qualifier
      // update the gift(s) quantity to match the qualifier. the if statement in
      // here serves as a guard to prevent looping. this task will run again for
      // each mutation, but the guard guarantees that the store will settle into
      // a constent state.
      for (let gift of gifts) {
        if (gift.quantity !== quantity) {
          store.commit('cart/setQuantityMutation', {
            id: gift.id,
            quantity
          })
        }
      }
    }
  }
}
const applyRedo = async (store, type, handle = '') => {
  const feeHandle = 'free-unlimited-return'
  const redoEnabled = get(store, 'state.cart.redoEnabled')
  const redoApplied = get(store, 'state.cart.redoApplied')
  const lineItems = get(store, 'state.cart.lineItems')
  const redoItem = lineItems.find((item) => item.handle === feeHandle)

  if (feeHandle === handle) {
    return
  }

  if (redoEnabled && redoApplied && lineItems.length > 0) {
    if (!redoItem) {
      if (!feeProductCache[feeHandle]) {
        let product = await store.$api.getProduct({
          handle: feeHandle
        })
        const variant = product.variants.find(variant => variant.price === '2.99')
        feeProductCache[feeHandle] = {
          ...product,
          variant: variant ?? get(product, 'variants[0]'),
        }
      }
      const feeObj = {
        ...clone(feeProductCache[feeHandle]),
        quantity: 1
      }
      store.commit('cart/addLineItemMutation', feeObj)
    }
    if (redoItem && lineItems.length === 1) {
      store.commit('cart/removeLineItemMutation', redoItem)
    }
  }
  if (redoEnabled && !redoApplied) {
    if (redoItem) {
      store.commit('cart/removeLineItemMutation', redoItem)
    }
  }
}

const getOptionsFromPayload = (type, payload) => {
  let result = null
  switch (type) {
  case 'cart/setLineItems':
    result = [type]
    break
  case 'cart/addLineItemMutation':
    result = [type, get(payload, 'handle')]
    break
  case 'cart/removeLineItemMutation':
    result = [type, get(payload, 'handle')]
    break
  case 'cart/incrementLineItemMutation':
    result = [type]
    break
  case 'cart/decrementLineItemMutation':
    result = [type]
    break
  case 'cart/addLineItemWithProps':
    result = [type, get(payload, 'item.handle')]
    break
  case 'cart/setLineItemProperty':
    result = [type]
    break
  case 'cart/removeLineItemProperties':
    result = [type]
    break
  case 'cart/addRedoToCart':
    result = [type]
    break
  case 'cart/removeRedoFromCart':
    result = [type]
    break
  }
  return result
}
/**
 * everything can run normally, plugin comes in after the fact to sanity check
 * and correct the line items. the goal here is to manage the complexity of
 * the cart without multiplying the problems complexity in the process.
 */
export const cartManagerPlugin = (store) => {
  /**
   * add/remove line item
   *   apply change
   *   do fees apply to the cart items?
   *     applyFees
   *   do limits apply for any cart items?
   *     inc/dec quantity
   * inc/dec line item
   *   apply change
   *   do fees apply to the cart items?
   *     applyFees
   *   do limits apply for any cart items?
   *     inc/dec quantity
   */
  const cartHandler = async (mutation) => {
    const { type, payload } = mutation
    // console.log('cartManager cartHandler', { type, payload })
    if (type === 'events/removeFromCart') {
      // handle gwp auto condition here because the cart/remove mutation doesn't
      // have the full line item object in it
      const item = get(payload, 'product')
      if (item) {
        const hasGwpAutoCondition = lineItemHasProps({
          item,
          keys: [ '_lineGiftWithPurchase', '_gwpauto' ]
        })
        if (hasGwpAutoCondition) {
          await setFlag('gwpauto-removed')
        }
      }
    }
    if (type.startsWith('cart/')) {
      const options = getOptionsFromPayload(type, payload)
      if (options) {
        // if you need to add a job that runs when the cart chages, add the
        // function to this list:
        const jobs = [applyFees, applyBogo, applyAddonSync]
        // run jobs serially, doing this in a Promise.all (concurrently) is just
        // asking for confusion.
        for (let job of jobs) {
          await job(store, ...options)
        }
      }
    }
  }
  if (process.client || (process.env.NODE_ENV === 'test')) {
    store.subscribe(cartHandler)
  }
}
