import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import { v4 as uuid } from 'uuid'
export const feeHandles = [
  'engraving-fee',
  'bracelet-sleeve-fee'
]
export const engravingKeys = [
  'font',
  'text',
  'location',
]
/**
 * is this value a primitive? (string, number, bool)
 * @param {*} v - the value that is being checked
 * @return {boolean}
 */
export const isPrimitive = (v) => isString(v) || isNumber(v) || isBoolean(v)
/**
 * generate unique id for line item
 * @param {object} payload
 * @return {string}
 */
export const getLineItemId = (payload) => `${payload.variant.id}::${uuid()}`
/**
 * @param {object} lineItem
 * @param {{key: String, value: String|Number|Boolean}[]} props
 */
export const mergeProps = (lineItem, props) => {
  let attrs = {}
  let result = []
  if (isArray(lineItem.customAttributes)) {
    for (let { key, value } of lineItem.customAttributes) {
      attrs[key] = value
    }
  }
  if (isArray(props)) {
    for (let prop of props) {
      attrs[prop.key] = prop.value
    }
  } else {
    console.warn('props is not an array!', props)
  }
  for (let [ key, value ] of Object.entries(attrs)) {
    result.push({ key, value })
  }
  // console.log('mergeProps', result)
  return result
}
/**
 * recursively flatten contentful objects
 * @param {object} value
 * @return {object}
 */
export const getItems = (value) => {
  let result
  if (isPrimitive(value)) {
    result = value
  } else if (isArray(value)) {
    result = []
    for (let item of value) {
      if (isPrimitive(item)) {
        result.push(item)
      } else {
        result.push(getItems(item.fields))
      }
    }
  } else if (value) {
    result = {}
    for (let [ k, v ] of Object.entries(value)) {
      result[k] = getItems(v)
    }
  }
  return result
}
/**
 * @param {object[]} lineItems
 * @param {{id: String, variant: {id: String}}|string} payload
 */
export const getLineItemIndex = (lineItems, payload) => {
  const payloadId = (isString(payload)) ? payload : payload.id
  let result = -1
  for (let [ index, lineItem ] of Object.entries(lineItems)) {
    if (lineItem.id === payloadId) {
      result = index
      break
    }
  }
  return result
}
/**
 * @param {object} item
 */
export const isLineItem = (item) => {
  const variantId = get(item, 'variant.id')
  // the line item id has the format of `{item.variant.id}::{uuid}`
  return (
    item.id.startsWith(variantId) &&
    (item.id.split('::').length === 2)
  )
}
