import { parseId } from '@/utils/parseIds'

/**
 * convert a nacelle `product.variants[x].id` field into an id that is usable by
 * shopping gives and other third parties that encode this value differently.
 * @param {string} nacelleProductVariantId
 * @return {string}
 */
export default (nacelleProductVariantId) => parseId(
  nacelleProductVariantId
)
