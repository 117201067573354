export const state = () => ({
  drawingMenus: {},
  showingMenu: false,
  isMobile: false,
  menuActive: false,
  navVisible: false,
  navHeight: null,
})

const updateMenu = (state, id, value) => {
  try {
    state.drawingMenus = {
      ...state.drawingMenus,
      [id]: { open: value }
    }
    //state.drawingMenus[id].open = value
  } catch (err) {
    console.error({ err, state, id })
    throw new Error('DrawingMenuMissing', { cause: err })
  }
}

export const mutations = {
  showDrawingMenu(state, id) {
    for (let id of Object.keys(state.drawingMenus)) {
      updateMenu(state, id, false)
    }
    updateMenu(state, id, true)
    state.navVisible = true
  },
  hideDrawingMenu(state, id) {
    updateMenu(state, id, false)
  },
  addDrawingMenus(state, items) {
    for (let id of items) {
      state.drawingMenus[id] = { open: false }
    }
  },
  closeAllDrawingMenu(state) {
    for (let id of Object.keys(state.drawingMenus)) {
      updateMenu(state, id, false)
    }
  },
  showNav(state) {
    state.navVisible = true
  },
  hideNav(state) {
    state.navVisible = false
    for (let id of Object.keys(state.drawingMenus)) {
      updateMenu(state, id, false)
    }
  },
  setMobile(state, value) {
    state.isMobile = !!value
  },
  setNavHeight(state, value) {
    state.navHeight = value
  }
}

export const getters = {
  mobileNavVisible({ isMobile, navVisible }, getters) {
    return isMobile && navVisible
  },
  subNavVisible(state) {
    let result = 0
    for (let { open } of Object.values(state.drawingMenus)) {
      if (open) {
        result += 1
      }
    }
    return result > 0
  }
}

export default {
  state,
  mutations,
  getters,
}
