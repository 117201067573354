/**
 * @link https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
 */
export default (store) => {
  const handler = ({ type }) => {
    switch (type) {
    case 'user/setUserData':
    case 'user/setAnonymousID':
      store.$sentry.setUser({
        id: store.getters['users/userID'],
        ip_address: '{{auto}}'
      })
      break
    }
  }
  if (process.client && store.$sentry) {
    store.subscribe(handler)
  }
}
