import clone from 'lodash/clone'
import get from 'lodash/get'
//import isString from 'lodash/isString'
import { getLineItem } from '@/utils/shopifyHelper'
import { getLineItemIndex } from '~/utils/cartHelper'
let bundleCache = {}
const updateLineItems = (lineItems, removeItems, addItems) => {
  let result = []
  result.push(...clone(addItems))
  for (let lineItem of lineItems) {
    const index = getLineItemIndex(removeItems, lineItem)
    if (index === (-1)) {
      result.push(clone(lineItem))
    }
  }
  // console.log({ cartItems: result })
  return result
}
const isBundle = (item, metafields, customAttributes) => {
  let result = false
  for (let { key } of metafields) {
    if (key === 'bundle_options') {
      result = true
      break
    }
  }
  return (
    result &&
    metafields.length &&
    customAttributes.length
  )
}
export const processBundles = async ($api, lineItems) => {
  let boxSets = []
  let lineItemsResult = []
  for (let item of lineItems) {
    const itemProps = get(item, 'customAttributes', [])
    const itemMeta = get(item, 'metafields', [])
    if (isBundle(item, itemMeta, itemProps)) {
      // read handles from here?
      const configData = itemProps.find(({ key }) => key === '_configurator_data')
      const bundleProducts = itemMeta.find(({ key }) => key === 'bundled_products')
      const bundleCollections = itemMeta.find(({ key }) => key === 'bundled_collections')
      const boxProduct = itemMeta.find(({ key }) => key === 'bundle_box')
      let products = []
      if ((bundleProducts || bundleCollections) && boxProduct) {
        const boxHandle = boxProduct.value
        if (!bundleCache[boxHandle]) {
          bundleCache[boxHandle] = await $api.getProduct({ handle: boxHandle })
        }
        products.push({ ...bundleCache[boxHandle] })
        // fetch the remaining products
        if (bundleProducts && bundleProducts.value) {
          for (let handle of bundleProducts.value.split('|')) {
            if (!bundleCache[handle]) {
              bundleCache[handle] = await $api.getProduct({ handle })
            }
            products.push({ ...bundleCache[handle] })
          }
        } else if (bundleCollections && bundleCollections.value) {
          for (let handle of bundleCollections.value.split('|')) {
            const collection = await $api.getCollection({ handle })
            //console.log('[bundle-helper]', { collection })
            products.push(...collection.products)
          }
        } else {
          console.error('[bundle-helper]', { bundleProducts, bundleCollections, boxProduct })
          throw new Error('[bundle-helper] missing products data, unable to process!')
        }
      } else {
        console.error('[bundle-helper]', { item, itemProps, itemMeta })
        throw new Error('[bundle-helper] missing bundled_products || bundle_box, unable to process!')
      }
      // console.log('bundleHack', { itemMeta, products })
      lineItemsResult.push(
        getLineItem(products[0], products[0].variants[0], {
          quantity: item.quantity,
          metafields: itemMeta,
          customAttributes: itemProps,
        })
      )
      for (let { quantity, part_id } of configData.value.items) {
        for (let product of products.slice(1)) {
          let match = false
          for (let variant of product.variants) {
            const sku = get(variant, 'sku')
            const rawSku = get(variant, 'metafieldsMap.raw_sku.value')
            if (
              (rawSku && (rawSku === part_id)) ||
              (sku && (sku === part_id))
            ) {
              lineItemsResult.push(
                getLineItem(product, variant, { quantity: quantity * item.quantity }),
              )
              match = true
              break
            }
          }
          if (match) {
            break
          }
        }
      }
      // console.log('bundleHack', { lineItemsResult, configData })
      boxSets.push(item)
    }
  }
  return updateLineItems(lineItems, boxSets, lineItemsResult)
}
