export default ({ store, route }) => {
  if (
    process.client &&
    (route.name === 'search') &&
    route.query.q
  ) {
    try {
      store.commit('search/setQuery', {
        origin: 'in-page',
        value: `${route.query.q}`
      })
    } catch (err) {
      console.error('[middleware/search]', err)
    }
  }
}
