/**
 * IF YOU'D LIKE TO CHANGE THE FORMAT
 * make sure you also update `src/liquid/snippets/account-cookie.liquid` in EnsoRings/ShopifyCheckout
 */
import Cookies from 'js-cookie'
import isString from 'lodash/isString'
import flow from 'lodash/fp/flow'
/**
 * @param {object} data
 */
export const setUserData = (data) => {
  const { userID, customerEmail, customerName } = data
  const userData = { userID, customerEmail, customerName }
  return {
    userData,
    cookie: Cookies.set('user-data', userData, { expires: 30 })
  }
}

/**
 * read stored user data
 * @return {string}
 */
export const getUserData = flow(
  () => {
    let data = {}
    try {
      data = Cookies.getJSON('user-data')
    } catch (err) {
      console.warn('[accountHelper::getUserData]', err)
      data = {}
    }
    return data || {}
  },
  /**
   * validate user data
   * @param {object} userData
   * @param {string} userData.userID
   * @param {string} userData.customerEmail
   * @param {string} userData.customerName
   */
  ({ userID, customerEmail, customerName }) => {
    let result = {}
    if (userID && isString(userID)) {
      const match = userID.match(/^([0-9]+)$/)
      if (match) {
        result.userID = match[1]
      }
    }
    if (customerEmail && isString(customerEmail)) {
      const match = customerEmail.match(/^([^@]+)@([^@]+)$/)
      if (match) {
        result.customerEmail = `${match[1]}@${match[2]}`
      }
    }
    if (customerName) {
      result.customerName = customerName
    }
    return result
  }
)

